import type { ReactElement } from "react"
import React, { useContext } from "react"
import SelectButtonAtm from "@onestore/hel/dist/components/atoms/SelectButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import HorizontalScrollItemsOrg from "@onestore/hel/dist/components/organisms/HorizontalScrollItemsOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import type { PeriodSwitcher } from "@gatsby-plugin-generic-page/fragments/periodsSwitcher"
import type { PeriodVariants } from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import getPeriodsByPeriodVariants from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import TogglePriceType from "~/components/TogglePriceType"
import SwitchContentContext from "~/context/SwitchContentContext"
import { productBoxClickEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

type SwitchPeriodAndTogglePriceTypeProps = {
  periodsData?: PeriodSwitcher["periods"]
  hasTogglePriceType?: boolean
  text?: string
  bottomText?: string
  defaultPeriod?: string
  initPeriods?: PeriodVariants
  showAdditionalButtonDescription?: boolean
  hasNoMarginBottom?: boolean
}

type PeriodButtonsProps = {
  periods: PeriodVariants
  currentKey: string
  setCurrentKey?: (key: string) => void
  showAdditionalButtonDescription?: boolean
}

function PeriodButtons({
  periods,
  currentKey,
  setCurrentKey,
  showAdditionalButtonDescription,
}: PeriodButtonsProps): ReactElement<PeriodButtonsProps> {
  return (
    <>
      {Object.values(periods).map(({ period, title, periodDescription }) => (
        <SelectButtonAtm
          borderRadius="huge"
          key={period}
          isActive={period === currentKey}
          onClick={() => {
            if (setCurrentKey) {
              setCurrentKey(period)
              productBoxClickEvent({
                productbox_type: "button",
                productbox_option: title,
                productboxbox_time: period,
              })
            }
          }}
        >
          {`${title} ${
            periodDescription && showAdditionalButtonDescription
              ? `• ${periodDescription}`
              : ""
          }`}
        </SelectButtonAtm>
      ))}
    </>
  )
}

function SwitchPeriodAndTogglePriceType({
  hasTogglePriceType,
  text,
  bottomText,
  defaultPeriod,
  initPeriods,
  showAdditionalButtonDescription,
  hasNoMarginBottom,
}: SwitchPeriodAndTogglePriceTypeProps): ReactElement<SwitchPeriodAndTogglePriceTypeProps> {
  const context = useContext(SwitchContentContext)
  const periods = !isEmpty(context?.periodVariants)
    ? getPeriodsByPeriodVariants(context?.periodVariants)
    : initPeriods
  const defaultPeriodValue = context?.currentKey || defaultPeriod
  const hasPeriodSwitcher = !isEmpty(periods) && defaultPeriodValue

  const desktopContent = (
    <>
      <FlexContainerOrg
        justifyContent={
          hasPeriodSwitcher && hasTogglePriceType ? "space-between" : "center"
        }
      >
        {hasPeriodSwitcher ? (
          <WrapOrg gutterSpace={1.5} alignCenter={"vertical"}>
            <PeriodButtons
              periods={periods}
              currentKey={defaultPeriodValue}
              setCurrentKey={context?.setCurrentKey}
              showAdditionalButtonDescription={showAdditionalButtonDescription}
            />
          </WrapOrg>
        ) : null}

        {hasTogglePriceType ? <TogglePriceType /> : null}
      </FlexContainerOrg>
    </>
  )

  return (
    <PushOrg
      bottomSpace={hasNoMarginBottom ? 0 : { small: 2, medium: 3, large: 4.5 }}
      hasForcedFullWidth={!!hasPeriodSwitcher}
    >
      {hasTogglePriceType ? (
        <BreakpointVisibilityOrg from="small" to="medium">
          <PushOrg bottomSpace={hasPeriodSwitcher ? 4 : 0} hasNoReset>
            <TogglePriceType />
          </PushOrg>
        </BreakpointVisibilityOrg>
      ) : null}

      {text && hasPeriodSwitcher ? (
        <TextAtm typography="xsmall2" pushSpace={2}>
          {text}
        </TextAtm>
      ) : null}

      <BreakpointVisibilityOrg from="small" to="medium">
        {hasPeriodSwitcher ? (
          <HorizontalScrollItemsOrg gutterSpace={1.5}>
            <PeriodButtons
              periods={periods}
              currentKey={defaultPeriodValue}
              setCurrentKey={context?.setCurrentKey}
              showAdditionalButtonDescription={showAdditionalButtonDescription}
            />
          </HorizontalScrollItemsOrg>
        ) : null}
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="medium">
        {desktopContent}
      </BreakpointVisibilityOrg>

      {bottomText && hasPeriodSwitcher ? (
        <TextAtm typography="nano1" pushSpace={{ top: 1.5 }}>
          {bottomText}
        </TextAtm>
      ) : null}
    </PushOrg>
  )
}

export default SwitchPeriodAndTogglePriceType
