import type {
  BasketLastItem,
  BasketResponse,
  BasketResponseItem,
  PriceValue,
} from "@onestore/api/basket"
import type {
  BasketPatchResourceWithResourceId,
  BasketPatchItem,
  PlanId,
  CalculateBasketPayload,
} from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import { itemsBasketPrice } from "@onestore/onestore-store-common/api/operations"
import {
  HTTP_STATUS,
  isResponsePending,
} from "@onestore/onestore-store-common/http"
import {
  addItemsToBasket,
  BasketActionSource,
  removeItemFromBasket,
  updateItemsInBasket,
} from "@gatsby-plugin-basket/store/actions"
import { IONOS_DC_PARAM_ID } from "@gatsby-plugin-bonus/components/FormBonusBoxParameters/IonosCloudDatacenterLocationSelect"
import { getChosenPeriod } from "@gatsby-plugin-bonus/store/utils"
import type {
  BonusPeriod,
  BonusProduct,
  DomainBonusProduct,
  ParametersPatchData,
} from "@gatsby-plugin-bonus/types"
import { requestProductDefinition } from "@gatsby-plugin-definitions/store/actions"
import api from "~/lib/api"
import { safeFind } from "~/lib/collection"
import type { AppState, AppDispatch } from "~/store/reducer"
import type { BonusThunkAction } from "./actions"
import { changeBonusItemPrice, createBonusItemPeriod } from "./actions-bonus"
import { getAdvancedProductsAliases } from "./selectors"
import { getBundled, getDomain } from "./selectors"

export enum BonusBundleActionType {
  BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_REQUEST = "@bonus/PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_REQUEST",
  BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_SUCCESS = "@bonus/PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_SUCCESS",
  BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_FAILURE = "@bonus/PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_FAILURE",

  BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_REQUEST = "@bonus/BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_REQUEST",
  BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_SUCCESS = "@bonus/BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_SUCCESS",
  BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_FAILURE = "@bonus/BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_FAILURE",

  BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_REQUEST = "@bonus/ADD_BUNDLED_TO_PRODUCT_IN_BASKET_REQUEST",
  BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_SUCCESS = "@bonus/ADD_BUNDLED_TO_PRODUCT_IN_BASKET_SUCCESS",
  BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_FAILURE = "@bonus/ADD_BUNDLED_TO_PRODUCT_IN_BASKET_FAILURE",
  BONUS_REMOVE_BUNDLED_FROM_BASKET_REQUEST = "@bonus/REMOVE_BUNDLED_FROM_BASKET_REQUEST",
  BONUS_REMOVE_BUNDLED_FROM_BASKET_SUCCESS = "@bonus/REMOVE_BUNDLED_FROM_BASKET_SUCCESS",
  BONUS_REMOVE_BUNDLED_FROM_BASKET_FAILURE = "@bonus/REMOVE_BUNDLED_FROM_BASKET_FAILURE",
  BONUS_CHANGE_BUNDLED_PERIOD_REQUEST = "@bonus/CHANGE_BUNDLED_PERIOD_REQUEST",
  BONUS_CHANGE_BUNDLED_PERIOD_SUCCESS = "@bonus/CHANGE_BUNDLED_PERIOD_SUCCESS",
  BONUS_CHANGE_BUNDLED_PERIOD_FAILURE = "@bonus/CHANGE_BUNDLED_PERIOD_FAILURE",

  // Bundle z domeną na Black Friday 2020
  BONUS_GET_BUNDLED_PRICE_REQUEST = "@bonus/GET_BUNDLED_PRICE_REQUEST",
  BONUS_GET_BUNDLED_PRICE_SUCCESS = "@bonus/GET_BUNDLED_PRICE_SUCCESS",
  BONUS_GET_BUNDLED_PRICE_FAILURE = "@bonus/GET_BUNDLED_PRICE_FAILURE",

  BONUS_CHANGE_BUNDLED_PRICE = "@bonus/CHANGE_BUNDLED_PRICE",
  BONUS_CHANGE_BUNDLED_PERIOD = "@bonus/CHANGE_BUNDLED_PERIOD",
}

interface BonusProductWithBundledAddToBasketRequestAction {
  type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_REQUEST
  automaticAdd: boolean
  status: HTTP.Status
}

interface BonusProductWithBundledAddToBasketSuccessAction {
  type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_SUCCESS
  basketItemId: number
  lastItems: BasketLastItem[]
  basketItemPrice: number
  basketItemTaxRate: number
  bundledBasketItemId: number
  bundledBasketItemPrice: PriceValue
  status: HTTP.Status
  chosenPeriodId: BonusProduct["chosenPeriodId"]
  bundledChosenPeriodId: BonusProduct["chosenPeriodId"]
}
interface BonusProductWithBundledAddToBasketFailureAction {
  status: HTTP.Status
  type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_FAILURE
}

interface BonusProductWithBundledCalculateBasketRequestAction {
  type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_REQUEST
  status: HTTP.Status
}

interface BonusProductWithBundledCalculateBasketSuccessAction {
  type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_SUCCESS
  items: BasketLastItem[]
  status: HTTP.Status
  product: BasketResponseItem | undefined
  bundled: BasketResponseItem | undefined
}

interface BonusProductWithBundledCalculateBasketFailureAction {
  status: HTTP.Status
  type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_FAILURE
}

interface BonusAddBundledToProductInBasketRequestAction {
  type: BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_REQUEST
  status: HTTP.Status
}
interface BonusAddBundledToProductInBasketSuccessAction {
  type: BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_SUCCESS
  status: HTTP.Status
  basketItemId: number
  lastItems: BasketLastItem[]
  bundledBasketItemPrice: PriceValue
  basketItemTaxRate: number
}

interface BonusAddBundledToProductInBasketFailureAction {
  type: BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_FAILURE
  status: HTTP.Status
}

interface BonusRemoveBundledFromBasketRequestAction {
  type: BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_REQUEST
  status: HTTP.Status
}
interface BonusRemoveBundledFromBasketSuccessAction {
  type: BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_SUCCESS
  status: HTTP.Status
}
interface BonusRemoveBundledFromBasketFailureAction {
  type: BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_FAILURE
  status: HTTP.Status
}

interface BonusChangeBundledPeriodRequestAction {
  period: BonusPeriod
  type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_REQUEST
  status: HTTP.Status
}
interface BonusChangeBundledPeriodSuccessAction {
  type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_SUCCESS
  status: HTTP.Status
}
interface BonusChangeBundledPeriodFailureAction {
  error: string
  type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_FAILURE
  status: HTTP.Status
}

interface BonusGetBundledPriceRequestAction {
  status: HTTP.Status
  type: BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_REQUEST
  bundled: BonusProduct
}
interface BonusGetBundledPriceSuccessAction {
  type: BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_SUCCESS
  status: HTTP.Status
  bundled: BonusProduct
}
interface BonusGetBundledPriceFailureAction {
  error: string
  type: BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_FAILURE
  status: HTTP.Status
  bundled: BonusProduct
}
interface BonusChangeBundledPriceAction {
  type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PRICE
  periods: BonusPeriod[]
}

export type BonusBundleActions =
  | BonusProductWithBundledAddToBasketRequestAction
  | BonusProductWithBundledAddToBasketSuccessAction
  | BonusProductWithBundledAddToBasketFailureAction
  | BonusAddBundledToProductInBasketRequestAction
  | BonusAddBundledToProductInBasketSuccessAction
  | BonusAddBundledToProductInBasketFailureAction
  | BonusRemoveBundledFromBasketRequestAction
  | BonusRemoveBundledFromBasketSuccessAction
  | BonusRemoveBundledFromBasketFailureAction
  | BonusChangeBundledPeriodRequestAction
  | BonusChangeBundledPeriodSuccessAction
  | BonusChangeBundledPeriodFailureAction
  | BonusGetBundledPriceRequestAction
  | BonusGetBundledPriceSuccessAction
  | BonusGetBundledPriceFailureAction
  | BonusChangeBundledPriceAction
  | BonusProductWithBundledCalculateBasketSuccessAction
  | BonusProductWithBundledCalculateBasketFailureAction
  | BonusProductWithBundledCalculateBasketRequestAction

function getResourcesForProduct(
  item: BonusProduct | DomainBonusProduct,
  chosenPeriodId: number
): BasketPatchResourceWithResourceId[] {
  const chosenPeriod = safeFind(
    item.periods,
    (period) => period.id === chosenPeriodId
  )

  const resourcesList: BasketPatchResourceWithResourceId[] = []

  chosenPeriod.resource_data.forEach((resource) => {
    const quantity = resource.lower_limit - resource.included_value

    if (quantity < 1) {
      return
    }

    resourcesList.push({
      resource: resource.resource_id,
      quantity,
    })
  })

  return resourcesList
}

function getAddToBasketPatchItems(
  product: (BonusProduct | DomainBonusProduct) & ParametersPatchData,
  bundled: BonusProduct | undefined,
  ignoreHack: boolean
): BasketPatchItem[] {
  const paramValues = Object.values(product.parameters)

  let requestParameters: Record<string, string> = {}

  if (paramValues.length > 0) {
    // ONESTORE-651 temporary vps parameter fix
    const paramNames = paramValues.map((param) => param.id)

    if (paramNames.includes(IONOS_DC_PARAM_ID)) {
      requestParameters = {
        [IONOS_DC_PARAM_ID]:
          "initialParamFormData" in product
            ? product.initialParamFormData?.[IONOS_DC_PARAM_ID] || "de/txl"
            : "de/txl",
      }
    }
  }

  const children = bundled
    ? [
        {
          plan: bundled.planId,
          planPeriod: bundled.chosenPeriodId,
          resources:
            bundled.quantityResource === null
              ? getResourcesForProduct(bundled, bundled.chosenPeriodId)
              : undefined,
          quantity:
            bundled.quantityResource !== null && bundled.minQuantity > 1
              ? bundled.minQuantity
              : 1,
        },
      ]
    : undefined

  const productHasQuantityResource =
    "quantityResource" in product && product.quantityResource !== null

  const productQuantity =
    "quantityResource" in product &&
    product.quantityResource !== null &&
    product.minQuantity > 1
      ? product.minQuantity
      : 1

  return [
    {
      plan: product.planId,
      planPeriod: product.chosenPeriodId,
      resources: !productHasQuantityResource
        ? getResourcesForProduct(product, product.chosenPeriodId)
        : undefined,
      quantity: productQuantity,
      children,
      parameters: { ...requestParameters, ...product?.patchParameters },
      ignoreHack,
    },
  ]
}

export function requestAddProductWithBundledToBasket(
  product: (BonusProduct | DomainBonusProduct) & ParametersPatchData,
  bundled: BonusProduct,
  promoCode: string | null = null,
  ignoreHack: boolean = false,
  automatic: boolean = false
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch(<BonusProductWithBundledAddToBasketRequestAction>{
      type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      product,
      automaticAdd: automatic,
      bundled,
    })

    try {
      const lastItems: BasketLastItem[] | undefined = await dispatch(
        addItemsToBasket(
          getAddToBasketPatchItems(product, bundled, ignoreHack),
          null,
          BasketActionSource.BONUS,
          () => {},
          promoCode
        )
      )

      if (lastItems) {
        const lastItem: BasketLastItem = lastItems[0]

        const chosenPeriod = getChosenPeriod(product)
        const chosenBundledPeriod = getChosenPeriod(bundled)

        dispatch(<BonusProductWithBundledAddToBasketSuccessAction>{
          type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_SUCCESS,
          basketItemId: lastItem.id,
          basketItemPrice: lastItem.price,
          basketItemTaxRate: lastItem.tax_rate,
          bundledBasketItemId:
            "children" in lastItem ? lastItem.children[0].id : undefined,
          bundledBasketItemPrice:
            "children" in lastItem ? lastItem.children[0].price : undefined,
          status: HTTP_STATUS.OK,
          chosenPeriodId: chosenPeriod?.id,
          chosenPeriodName: chosenPeriod?.period_name,
          bundledChosenPeriodId: chosenBundledPeriod?.id,
          bundledChosenPeriodName: chosenBundledPeriod?.period_name,
          lastItems,
        })

        return Promise.resolve(lastItem)
      }

      return Promise.resolve(null)
    } catch (error) {
      dispatch(<BonusProductWithBundledAddToBasketFailureAction>{
        type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_ADD_TO_BASKET_FAILURE,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
      })

      return Promise.reject()
    }
  }
}

/**
 * Zmiana okresu zbundlowanego produktu w przed-koszyku.
 *
 * @param   {number} itemId    ID elementu koszyka, któremu zmieniamy okres.
 * @param   {object} period    Okres na jaki chcemy zmienić.
 * @returns {Promise.<object>} Zwraca zmodyfikowany element koszyka.
 */
export function requestChangeBundledProductPeriod(
  itemId: number,
  period: BonusPeriod
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch(<BonusChangeBundledPeriodRequestAction>{
      type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      period,
      itemId,
    })

    try {
      const lastItems: BasketLastItem[] = await dispatch(
        updateItemsInBasket(
          [
            {
              id: itemId,
              planPeriod: period.id,
            },
          ],
          BasketActionSource.BONUS
        )
      )

      dispatch(<BonusChangeBundledPeriodSuccessAction>{
        type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_SUCCESS,
        status: HTTP_STATUS.OK,
      })

      return Promise.resolve(lastItems[0])
    } catch (error) {
      dispatch(<BonusChangeBundledPeriodFailureAction>{
        type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_FAILURE,
        status: HTTP_STATUS.BAD_REQUEST,
      })

      return Promise.reject(error)
    }
  }
}

export function requestAddBundledToProduct(
  itemId: number,
  bundled: BonusProduct
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch(<BonusAddBundledToProductInBasketRequestAction>{
      type: BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      bundled,
      itemId,
    })

    try {
      const lastItems = await dispatch(
        addItemsToBasket(
          [
            {
              plan: bundled.planId,
              planPeriod: bundled.chosenPeriodId,
              parent: itemId,
              quantity:
                bundled.quantityResource !== null && bundled.minQuantity > 1
                  ? bundled.minQuantity
                  : 1,
            },
          ],
          null,
          BasketActionSource.BONUS
        )
      )

      if (lastItems) {
        const lastItem = lastItems[0]

        if ("plan_id" in lastItem) {
          dispatch(<BonusAddBundledToProductInBasketSuccessAction>{
            type: BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_SUCCESS,
            basketItemId: lastItem.id,
            status: HTTP_STATUS.OK,
            lastItems,
            bundledBasketItemPrice: lastItem.price,
          })
        }
      }

      return Promise.resolve(lastItems)
    } catch (error) {
      dispatch(<BonusAddBundledToProductInBasketFailureAction>{
        type: BonusBundleActionType.BONUS_ADD_BUNDLED_TO_PRODUCT_IN_BASKET_FAILURE,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
      })

      return Promise.reject(error)
    }
  }
}

export function requestRemoveBundled(itemId: number, planId: PlanId) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch(<BonusRemoveBundledFromBasketRequestAction>{
      type: BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
    })

    try {
      const response = await dispatch(
        removeItemFromBasket(itemId, planId, null, BasketActionSource.BONUS)
      )
      dispatch(<BonusRemoveBundledFromBasketSuccessAction>{
        type: BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_SUCCESS,
        status: HTTP_STATUS.OK,
      })

      return Promise.resolve(response)
    } catch (error) {
      dispatch(<BonusRemoveBundledFromBasketFailureAction>{
        type: BonusBundleActionType.BONUS_REMOVE_BUNDLED_FROM_BASKET_FAILURE,
        status: HTTP_STATUS.BAD_REQUEST,
      })

      return Promise.reject(error)
    }
  }
}

/**
 * Wysyła request do api o cenę bundlowanego produktu z domeną.
 */
async function getDomainBundlePrice(
  domain: DomainBonusProduct,
  bundled: BonusProduct
) {
  const items = bundled.periods.map((bundledPeriod) => ({
    plan: domain.planId,
    quantity: 1,
    parameters: {
      domain: `domain.${domain.alias}`,
    },
    children: [createBonusItemPeriod(bundled, bundledPeriod)],
  }))

  const promises = items.map((item) =>
    api.calculateBasket({ items: itemsBasketPrice([item]) })
  )
  const results = await Promise.all(promises)

  return Promise.resolve(
    results.map((result: BasketResponse) => result.items[0].children)
  )
}

export function changeBundledPrice(
  bundled: BonusProduct,
  items: BasketResponseItem[][]
) {
  return (
    dispatch: AppDispatch<BonusThunkAction>
  ): Promise<BonusProduct["periods"]> => {
    const periods = changeBonusItemPrice(bundled, items)

    dispatch(<BonusChangeBundledPriceAction>{
      type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PRICE,
      periods,
    })

    return Promise.resolve(periods)
  }
}

export function requestGetBundledDomainPrice(bundled: BonusProduct) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch(<BonusGetBundledPriceRequestAction>{
      type: BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      bundled,
    })

    const state = getState()

    const domain = getDomain(state)

    try {
      dispatch(<BonusGetBundledPriceSuccessAction>{
        type: BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_SUCCESS,
        status: HTTP_STATUS.OK,
        bundled,
      })

      const items = await getDomainBundlePrice(domain, bundled)

      const updates = await dispatch(changeBundledPrice(bundled, items))

      return Promise.resolve(updates)
    } catch (error) {
      return dispatch(<BonusGetBundledPriceFailureAction>{
        type: BonusBundleActionType.BONUS_GET_BUNDLED_PRICE_FAILURE,
        status: error.code,
        error,
        bundled,
      })
    }
  }
}

export function changeBundledProductPeriod(period: BonusPeriod) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const bundled = getBundled(getState())

    if (
      !bundled ||
      period.id === bundled.chosenPeriodId ||
      isResponsePending(bundled.changePeriodStatus)
    ) {
      return
    }

    if (bundled.basketItemState?.id) {
      dispatch(
        requestChangeBundledProductPeriod(bundled.basketItemState.id, period)
      )

      return
    }

    dispatch(<BonusChangeBundledPeriodRequestAction>{
      type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      period,
    })

    dispatch(<BonusChangeBundledPeriodSuccessAction>{
      type: BonusBundleActionType.BONUS_CHANGE_BUNDLED_PERIOD_SUCCESS,
      status: HTTP_STATUS.OK,
    })
  }
}

export function requestBonusProductsDefinitions() {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const aliases = getAdvancedProductsAliases(getState())

    aliases.forEach((alias) => {
      dispatch(requestProductDefinition(alias))
    })
  }
}
export function requestInitialBonusCalculation(
  product: (BonusProduct | DomainBonusProduct) & ParametersPatchData,
  bundled: BonusProduct | undefined,
  promoCode: string | null = null
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch({
      type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
    })

    const request: CalculateBasketPayload = {
      items: getAddToBasketPatchItems(product, bundled, false),
    }

    if (promoCode) {
      request.promo_code = promoCode
    }

    api
      .calculateBasket(request)
      .then((response) => {
        const productItem: BasketResponseItem | undefined = response.items.find(
          (item) => item.plan_id === product.planId
        )
        try {
          dispatch({
            type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_SUCCESS,
            product: productItem,
            bundled: productItem?.children.find(
              (item) => item.plan_id === bundled?.planId
            ),
          })
        } catch (error) {
          dispatch({
            type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_FAILURE,
            status: error.code || HTTP_STATUS.BAD_REQUEST,
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: BonusBundleActionType.BONUS_PRODUCT_WITH_BUNDLED_CALCULATE_BASKET_FAILURE,
          status: error.code || HTTP_STATUS.BAD_REQUEST,
        })
      })
  }
}
