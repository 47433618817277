/**
 * @module ImageAnchorButton
 * @category Components
 */

import type { ReactElement, ReactNode, SyntheticEvent } from "react"
import React from "react"
import ImageLinkAtm from "@onestore/hel/dist/components/atoms/ImageLinkAtm"
import type { GaEvent } from "~/fragments/gaEvent"
import useAnchorProperties from "~/hooks/useAnchorProperties"
import { sendGaEvent } from "~/lib/ga4"

export interface Props {
  image: ReactNode
  title: string
  anchor: string
  gaEvent?: GaEvent
}

/**
 * This Component renders an anchor element that links to a specified ID within the page.
 * It features a clickable image that serves as a visual trigger for the link.
 *
 * @example
 * <ImageAnchorButton
 *  image={<Media media={imageProps} />}
 *  title="lorem ipsum"
 *  anchor="section2"
 * />
 */
function ImageAnchorButton({
  image,
  title,
  anchor,
  gaEvent,
}: Props): ReactElement<Props> {
  const { onClick } = useAnchorProperties(anchor)

  const handleOnClick = (event: SyntheticEvent) => {
    sendGaEvent(gaEvent)

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <ImageLinkAtm
      image={image}
      title={title}
      {...useAnchorProperties(anchor)}
      onClick={handleOnClick}
    />
  )
}

export default ImageAnchorButton
