import type { ReactElement } from "react"
import React, { useContext } from "react"
import { typeMatches } from "@onestore-graphql"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import AXLinkButton from "@gatsby-plugin-generic-page/components/AXLinkButton"
import BasketButton from "@gatsby-plugin-generic-page/components/BasketButton"
import FastCheckoutButton from "@gatsby-plugin-generic-page/components/FastCheckoutButton"
import PriceWithButtonWrapper from "@gatsby-plugin-generic-page/components/PriceWithButtonWrapper"
import ProductPrice from "@gatsby-plugin-generic-page/components/ProductPrice"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import AnchorButton from "~/components/AnchorButton"
import SearchUrlParametersContext from "~/context/SearchUrlParametersContext"
import type { GaEvent } from "~/fragments/gaEvent"
import useModalState from "~/hooks/useModalState"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import CtaModal from "../CtaModal"
import FormButton from "../FormButton"

type Props = {
  button: CtaElement
  isWider?: boolean
  isOverlappingRelativeContainer?: boolean
  isExpandedOnMobile?: boolean
}

type CommonProps = Pick<
  ButtonAtmProps,
  | "color"
  | "variant"
  | "title"
  | "text"
  | "isWider"
  | "isExpanded"
  | "isExpandedOnMobile"
  | "isOverlappingRelativeContainer"
  | "onClick"
  | "rel"
  | "size"
> & {
  gaEvent?: GaEvent
}

function Button({ button, ...restProps }: Props): ReactElement<Props> | null {
  const {
    actionTypeName,
    text,
    title,
    variant,
    buttonColor,
    isWider,
    isExpanded,
    isExpandedOnMobile,
    size,
    showPrice,
    hasSurface,
    gaEvent,
    anchorId,
    form,
    href,
    openInNewTab,
    pages,
    modalTitle,
    texts,
    plan,
    fastCheckoutPlan,
    saleConfiguration,
    rel,
  } = getCtaButtonFlatData(button)
  const { isOpen, open, close } = useModalState(gaEvent)
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)

  if (isEmpty(actionTypeName)) {
    return null
  }

  let ctaButton

  const commonProps: CommonProps = {
    color: buttonColor.color,
    variant,
    title,
    text,
    isWider,
    isExpanded,
    size: !isEmpty(size) ? size : undefined,
    rel: !isEmpty(rel) ? rel : undefined,
    ...restProps,
    isExpandedOnMobile: restProps.isExpandedOnMobile || isExpandedOnMobile,
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.SCROLL_TO_SECTION) &&
    !isEmpty(anchorId)
  ) {
    ctaButton = (
      <AnchorButton {...commonProps} anchor={anchorId} gaEvent={gaEvent} />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.OPEN_FORM) &&
    !isEmpty(form)
  ) {
    ctaButton = <FormButton {...commonProps} form={form} gaEvent={gaEvent} />
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.OPEN_MODAL) &&
    !isEmpty(texts)
  ) {
    ctaButton = (
      <>
        <ButtonAtm {...commonProps} onClick={open}>
          {text}
        </ButtonAtm>

        <CtaModal
          isOpen={isOpen}
          texts={texts}
          title={modalTitle}
          onCloseButtonClick={close}
        />
      </>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.INTERNAL_LINK) &&
    !isEmpty(pages)
  ) {
    ctaButton = (
      <ButtonAtm
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        to={pages[0].flatData.url}
      >
        {text}
      </ButtonAtm>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.EXTERNAL_LINK) &&
    !isEmpty(href)
  ) {
    ctaButton = (
      <ButtonAtm
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        href={replaceLinkValues(href, currentSearchUrlParameters)}
        openInNewTab={openInNewTab}
      >
        {text}
      </ButtonAtm>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.ADD_TO_BASKET) &&
    !isEmpty(plan)
  ) {
    ctaButton = (
      <PriceWithButtonWrapper hasSurface={!!hasSurface}>
        <BasketButton
          {...commonProps}
          hasSurface={!!hasSurface}
          hidePrice={isEmpty(showPrice?.plan)}
          plan={plan}
          onClick={() => sendGaEvent(gaEvent)}
        />
      </PriceWithButtonWrapper>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.FAST_CHECKOUT) &&
    !isEmpty(fastCheckoutPlan)
  ) {
    ctaButton = (
      <FastCheckoutButton
        {...commonProps}
        plan={fastCheckoutPlan}
        onClick={() => sendGaEvent(gaEvent)}
      />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.CTA_AX_LINK) &&
    !isEmpty(saleConfiguration)
  ) {
    ctaButton = (
      <AXLinkButton
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        saleConfiguration={saleConfiguration}
      />
    )
  }

  return (
    <ConditionalWrapper
      key={button.id}
      wrapper={(children) => (
        <FlexContainerOrg alignItems="center" display="inline-flex">
          <PushOrg rightSpace={2}>
            <ProductPrice isSmall isVertical plan={showPrice?.plan} />
          </PushOrg>
          {children}
        </FlexContainerOrg>
      )}
      condition={
        !isEmpty(showPrice?.plan) &&
        !typeMatches(actionTypeName, SquidexComponentType.ADD_TO_BASKET)
      }
    >
      {ctaButton}
    </ConditionalWrapper>
  )
}

export default Button
