import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import _t from "@core/i18n"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import PackageBoxSlimMol from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { getStaticPrices } from "@gatsby-plugin-generic-page/helpers/staticPrices"
import ProductBoxPrice from "~/components/ProductBoxPrice"
import type { StaticPrice } from "~/fragments/staticPrice"
import useAnchorProperties from "~/hooks/useAnchorProperties"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import ProductBoxSlimFooter from "./ProductBoxFooter"

export interface ProductBoxWithCtaAnchorProps {
  product
  anchorId
  cta: CtaElement[]
  staticPrices?: StaticPrice[] | null
}

export default function ProductBoxWithCtaAnchor({
  product,
  anchorId,
  cta,
  staticPrices,
}: ProductBoxWithCtaAnchorProps): ReactElement<ProductBoxWithCtaAnchorProps> {
  const prices = staticPrices
    ? getStaticPrices(staticPrices, product.priceType)
    : {}

  const { text, title, variant, gaEvent } = getCtaButtonFlatData(cta[0])

  const { href, onClick } = useAnchorProperties(anchorId)

  const period =
    Object.keys(prices).length === 1
      ? Object.keys(prices)[0]
      : product.period || Object.keys(prices)[0]

  const handleOnClick = (event: SyntheticEvent) => {
    sendGaEvent(gaEvent)

    onClick(event)
  }

  return (
    <PackageBoxSlimMol
      {...product}
      button={{
        text,
        title,
        href: href,
        onClick: handleOnClick,
        color: product.buttonColor,
        variant,
      }}
      price={<ProductBoxPrice {...prices[period].price} />}
      renewalPayment={
        (prices &&
          prices[period].renewPrice &&
          _t("packageBoxSlim.renewalPrice", {
            price: prices[period].renewPrice?.value,
            suffix: prices[period].renewPrice?.suffix,
          })) ||
        product.additionalText ||
        undefined
      }
      label={
        !isEmpty(product.discountLabel) ? (
          <LabelAtm
            size="small"
            variant={product.discountLabelVariant ?? "notify"}
            wrapLabel
          >
            {product.discountLabel}
          </LabelAtm>
        ) : null
      }
      parameters={product.content.parameters}
      footer={
        product.content?.footer ? (
          <ProductBoxSlimFooter {...product.content.footer} />
        ) : undefined
      }
    />
  )
}
