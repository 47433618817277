import type { ReactElement } from "react"
import { useState } from "react"
import React from "react"
import _t from "@core/i18n"
import { typeMatches } from "@onestore-graphql"
import type { AdvancedProductCardMolProps } from "@onestore/hel/dist/components/molecules/AdvancedProductCardMol"
import AdvancedProductCardMol from "@onestore/hel/dist/components/molecules/AdvancedProductCardMol"
import type { CtaForm } from "@gatsby-plugin-generic-page/fragments/ctaForm"
import type { InternalLink } from "@gatsby-plugin-generic-page/fragments/internalLink"
import type { ProductCardFlatData } from "@gatsby-plugin-generic-page/fragments/productCard"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import getProductCardLogo from "@gatsby-plugin-generic-page/helpers/getLogo"
import getProductCardFooterData from "@gatsby-plugin-generic-page/helpers/getProductCardFooterData"
import getProductCardPeriodText from "@gatsby-plugin-generic-page/helpers/getProductCardPeriodText"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import Price from "~/components/AdvancedProductCard/components/Price"
import StaticPrice from "~/components/AdvancedProductCard/components/StaticPrice"
import FormModal from "~/components/FormModal"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { Form } from "~/fragments/form"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import type { ExternalLink, FormButton } from "~/types/squidex"

function ProductCard(
  card: ProductCardFlatData
): ReactElement<AdvancedProductCardMolProps> {
  const { currentPriceType } = usePriceTypeContext()
  const [visible, setVisible] = useState(false)

  const {
    headerImage,
    heading,
    text,
    button,
    cardLink,
    label,
    logo,
    logoBackgroundColor,
    footer,
  } = card.flatData

  const link = getLinkProps(cardLink)
  const {
    productPeriodInfo,
    staticPrice,
    priceHasPrimaryColor,
    priceText,
    tooltip,
  } = getProductCardFooterData(footer, currentPriceType)
  const { logoElement, textBox } = getProductCardLogo(logo, logoBackgroundColor)

  const {
    color: buttonColor,
    gaEvent,
    text: buttonText,
    title,
    variant,
    link: buttonLink,
  } = button

  let href: string | undefined,
    to: string | undefined,
    openInNewTab: boolean | undefined

  if (typeMatches(buttonLink.__typename, SquidexComponentType.EXTERNAL_LINK)) {
    const { href: hrefLink, openInNewTab: openNewTab } =
      buttonLink as ExternalLink

    href = hrefLink
    openInNewTab = openNewTab
  }

  if (typeMatches(buttonLink.__typename, SquidexComponentType.INTERNAL_LINK)) {
    const { pages, openInNewTab: openNewTab } = buttonLink as InternalLink

    to = !isEmpty(pages) ? pages[0].flatData.url : undefined
    openInNewTab = openNewTab
  }

  let formButton: FormButton = {
      title: "",
      text: "",
      buttonColor: { color: "primary" },
    },
    formId: string = "",
    formTitle: string = ""

  let isFormType = false

  if (typeMatches(buttonLink.__typename, SquidexComponentType.OPEN_FORM)) {
    const formButtonLink = buttonLink as CtaForm
    const form = formButtonLink.form[0]

    if (!isEmpty(form)) {
      isFormType = true
      const { button, title, id } = form.flatData as Form["flatData"]

      formButton = button[0]
      formId = id
      formTitle = title || ""
    }
  }

  const onCardButtonClick = () => {
    sendGaEvent(gaEvent)

    isFormType && setVisible(true)
  }

  const productTileLabel = !isEmpty(label)
    ? { children: label.text, variant: label.variant }
    : undefined

  const periodText = getProductCardPeriodText(
    productPeriodInfo,
    currentPriceType
  )

  const hasPromotion = productPeriodInfo?.hasPromotion() ?? false

  return (
    <>
      <AdvancedProductCardMol
        backgroundImage={assetsSquidexToImageObject(headerImage, "")}
        title={heading}
        description={text}
        link={link}
        logo={logoElement}
        textBox={textBox}
        label={
          hasPromotion
            ? {
                children: _t("marketplace.discountTitle"),
                variant: "notifyLight",
              }
            : productTileLabel
        }
        price={
          productPeriodInfo ? (
            <Price
              periodInfo={productPeriodInfo}
              priceType={currentPriceType}
              periodText={periodText}
              priceHasPrimaryColor={priceHasPrimaryColor}
              tooltip={tooltip}
            />
          ) : staticPrice ? (
            <StaticPrice
              {...staticPrice}
              priceHasPrimaryColor={priceHasPrimaryColor}
            />
          ) : undefined
        }
        priceText={priceText}
        button={{
          text: buttonText,
          title: title,
          openInNewTab: openInNewTab,
          href: href,
          to: to,
          onClick: onCardButtonClick,
        }}
        buttonColor={!isEmpty(buttonColor) ? buttonColor.color : undefined}
        buttonVariant={!isEmpty(variant) ? variant.variant : undefined}
      />

      {visible && isFormType ? (
        <FormModal
          headingText={formTitle}
          id={formId}
          button={{ ...formButton }}
          onCloseButtonClick={() => {
            setVisible(false)
          }}
        />
      ) : null}
    </>
  )
}

export default ProductCard
