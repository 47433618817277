import type { ReactElement } from "react"
import React, { useEffect, useState } from "react"
import _t from "@core/i18n"
import { useLocation } from "@reach/router"
import { trimStart } from "lodash"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import SelectButtonAtm from "@onestore/hel/dist/components/atoms/SelectButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AdvancedProductTilesOrg from "@onestore/hel/dist/components/organisms/AdvancedProductTilesOrg"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import HorizontalScrollItemsOrg from "@onestore/hel/dist/components/organisms/HorizontalScrollItemsOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { AdvancedDomainItemsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/advancedDomainItemsSection"
import useDomainExtensionsByCategories from "@gatsby-plugin-onestore/hooks/useDomainExtensionsByCategories"
import TogglePriceType from "~/components/TogglePriceType"
import { useSessionStorage } from "~/hooks/useSessionStorage"
import { sendContentShowEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import type { DomainExtensionCategory } from "~/types/squidex"
import AdvancedDomainItem from "./AdvancedDomainItem"

const getCategoryIndex = (domainData: DomainExtensionCategory[]): number => {
  const categoryFromUrl = trimStart(window.location.hash, "#")

  return domainData.findIndex(
    (category) => category.flatData.slug === categoryFromUrl
  )
}

function AdvancedDomainItemsSection({
  activeColor,
  sectionSettings,
  hasTogglePriceType = false,
  header,
}: AdvancedDomainItemsSectionFlatData): ReactElement<AdvancedDomainItemsSectionFlatData> {
  const domainData = useDomainExtensionsByCategories()
  const location = useLocation()

  useEffect(() => {
    if (!location.hash) {
      return
    }

    const categoryIndex = getCategoryIndex(domainData)

    if (categoryIndex === -1) {
      return
    }

    setActiveDesktopDomainsIndex(categoryIndex)
  }, [])

  const [isMobileDomainsLimited, setIsMobileDomainsLimited] = useState(true)
  const [activeStoredDesktopDomainsIndex, setActiveDesktopDomainsIndex] =
    useSessionStorage<number>("advanced-domain-page-items-tab", 0)

  const activeDesktopDomainsIndex =
    activeStoredDesktopDomainsIndex >= domainData.length
      ? 0
      : activeStoredDesktopDomainsIndex

  const [isExpanded, setExpanded] = useSessionStorage<boolean>(
    "advanced-domain-page-items-button",
    false
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {header ? (
          <PushOrg bottomSpace={8}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <BreakpointVisibilityOrg from="small" to="large">
          {domainData.map((item, index) =>
            isMobileDomainsLimited && index >= 2 ? null : (
              <PushOrg bottomSpace={3} key={item.flatData.name}>
                <TextAtm typography="big3" pushSpace={2}>
                  {item.flatData.name}
                </TextAtm>

                <HorizontalScrollItemsOrg gutterSpace={1.5} itemWidthSpace={37}>
                  {item.flatData.domains
                    .filter((domain) => !isEmpty(domain.flatData.pages))
                    .map((domain) => (
                      <AdvancedDomainItem
                        planId={domain.flatData.id}
                        label={domain.flatData.label}
                        extension={domain.flatData.extension}
                        periods={domain.flatData.periods}
                        description={domain.flatData.description}
                        title={domain.flatData.title}
                        link={
                          isEmpty(domain.flatData.pages)
                            ? undefined
                            : domain.flatData.pages[0].flatData.url
                        }
                        key={`${item.flatData.name}/${domain.flatData.extension}`}
                        logo={
                          isEmpty(domain.flatData.logo)
                            ? undefined
                            : domain.flatData.logo![0].url
                        }
                      />
                    ))}
                </HorizontalScrollItemsOrg>
              </PushOrg>
            )
          )}

          {isMobileDomainsLimited ? (
            <AlignContentOrg horizontalAlign="center">
              <ButtonAtm
                variant="outlined"
                color="secondary"
                title={_t("domains.showNextCategory")}
                onClick={() => {
                  sendContentShowEvent()
                  setIsMobileDomainsLimited(false)
                }}
              >
                {_t("domains.showNextCategory")}
              </ButtonAtm>
            </AlignContentOrg>
          ) : null}
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="large">
          <PushOrg bottomSpace={5}>
            <FlexContainerOrg
              justifyContent="space-between"
              alignItems="center"
            >
              <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
                <WrapOrg gutterSpace={1} data-test-id="domain-ext-categories">
                  {domainData.map((item, index) => (
                    <SelectButtonAtm
                      onClick={() => setActiveDesktopDomainsIndex(index)}
                      isActive={index === activeDesktopDomainsIndex}
                      activeColor={
                        !isEmpty(activeColor) ? activeColor : undefined
                      }
                      key={item.flatData.name}
                    >
                      {item.flatData.name}
                    </SelectButtonAtm>
                  ))}
                </WrapOrg>
              </FlexItemOrg>

              {hasTogglePriceType ? (
                <PushOrg leftSpace={3}>
                  <TogglePriceType />
                </PushOrg>
              ) : null}
            </FlexContainerOrg>
          </PushOrg>

          {domainData.map((item, categoryIndex) => {
            const maxNumberOfDomains = 8
            const hasTooManyDomains =
              item.flatData.domains.length > maxNumberOfDomains

            return (
              <div
                style={
                  activeDesktopDomainsIndex !== categoryIndex
                    ? {
                        display: "none",
                      }
                    : undefined
                }
                key={item.flatData.name}
              >
                <AdvancedProductTilesOrg columns={4} gutterSpace={2.5}>
                  {item.flatData.domains
                    .filter((domain) => !isEmpty(domain.flatData.pages))
                    .map((domain, domainIndex) => (
                      <div
                        style={{
                          display:
                            hasTooManyDomains &&
                            !isExpanded &&
                            domainIndex >= maxNumberOfDomains
                              ? "none"
                              : "grid",
                        }}
                        key={`${item.flatData.name}/${domain.flatData.extension}`}
                      >
                        <AdvancedDomainItem
                          planId={domain.flatData.id}
                          label={domain.flatData.label}
                          extension={domain.flatData.extension}
                          title={domain.flatData.title}
                          periods={domain.flatData.periods}
                          description={domain.flatData.description}
                          link={
                            isEmpty(domain.flatData.pages)
                              ? undefined
                              : domain.flatData.pages[0].flatData.url
                          }
                          logo={
                            isEmpty(domain.flatData.logo)
                              ? undefined
                              : domain.flatData.logo![0].url
                          }
                        />
                      </div>
                    ))}
                </AdvancedProductTilesOrg>

                {hasTooManyDomains && !isExpanded ? (
                  <PushOrg topSpace={5}>
                    <AlignContentOrg horizontalAlign="center">
                      <ButtonAtm
                        variant="outlined"
                        color="secondary"
                        title={_t("domains.showNextDomains")}
                        onClick={() => {
                          sendContentShowEvent()
                          setExpanded(true)
                        }}
                      >
                        {_t("domains.showNextDomains")}
                      </ButtonAtm>
                    </AlignContentOrg>
                  </PushOrg>
                ) : null}
              </div>
            )
          })}
        </BreakpointVisibilityOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default AdvancedDomainItemsSection
