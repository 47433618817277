import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import { get } from "lodash"
import type { AdvancedProductCardMolProps } from "@onestore/hel/dist/components/molecules/AdvancedProductCardMol"
import getProductCardPeriodText from "@gatsby-plugin-generic-page/helpers/getProductCardPeriodText"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import type { ProductCardItem } from "@gatsby-plugin-marketplace/fragments/marketplaceProductCard"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import isEmpty from "~/lib/isEmpty"
import url from "~/lib/url"
import Price from "../components/AdvancedProductCard/components/Price"
import type { LinkPropertiesCollectionHook } from "./useLinkPropertiesCollection"

export default function useAdvancedProductCard(
  productData: ProductCardItem,
  linkData: LinkPropertiesCollectionHook
): AdvancedProductCardMolProps {
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()

  const {
    name,
    headerCardBg,
    headerLogo,
    cardDescription,
    priceText,
    pages,
    saleConfigurations,
    hasPricePrefix,
  } = productData.flatData

  const backgroundImage = assetsSquidexToImageObject(headerCardBg, name)

  const logo = {
    image: assetsSquidexToImageObject(headerLogo, name),
  }

  const description = cardDescription || ""

  const pageUrl = pages[0].flatData.url

  const link = {
    ...linkData[pageUrl],
    text: _t("viewDetails"),
    title: name,
  }

  const saleConfiguration =
    !isEmpty(saleConfigurations) && saleConfigurations[0]

  const parameters = saleConfiguration
    ? saleConfiguration.flatData.config.parameters
    : undefined

  const defaultCloudBluePlan = saleConfiguration
    ? saleConfiguration.flatData.defaultCloudBluePlan
    : undefined

  const bonusLink =
    saleConfiguration &&
    url.generateBonusUrl({
      p: !isEmpty(defaultCloudBluePlan)
        ? defaultCloudBluePlan[0].flatData.alias
        : undefined,
      ax: getUpsellData(saleConfiguration.flatData.upsell),
      priceType: currentPriceType,
    })

  const optionsMapping = saleConfiguration
    ? saleConfiguration.flatData.optionsMapping
    : undefined

  const defaultCloudBluePlanPeriods = !isEmpty(defaultCloudBluePlan)
    ? defaultCloudBluePlan[0].flatData.periods
    : undefined

  const defaultPeriod = !isEmpty(defaultCloudBluePlanPeriods)
    ? defaultCloudBluePlanPeriods.find((period) => period.default)
    : undefined

  const periodInfo = defaultPeriod ? new PeriodInfo(defaultPeriod) : undefined

  const renewPrice =
    periodInfo && periodInfo.period.price_renew
      ? periodInfo.getFormatted(
          isNettoPriceType
            ? PriceFormat.RENEW_NETTO_NO_CURRENCY_NO_PERIOD
            : PriceFormat.RENEW_GROSS_NO_CURRENCY_NO_PERIOD
        )
      : undefined

  const suffix = periodInfo
    ? periodInfo.getFormatted(
        isNettoPriceType
          ? PriceFormat.NETTO_WITH_PERIOD
          : PriceFormat.GROSS_WITH_PERIOD
      )
    : undefined

  const prefix =
    !isEmpty(optionsMapping) || hasPricePrefix ? _t("prices.from") : undefined

  const currency = periodInfo
    ? periodInfo.getFormatted(PriceFormat.CURRENCY)
    : undefined

  const periodText = getProductCardPeriodText(periodInfo, currentPriceType)

  const tooltip = !isEmpty(defaultCloudBluePlan)
    ? get(
        defaultCloudBluePlan[0],
        "flatData.promotionPriceTooltip[0].flatData.text"
      )
    : undefined

  const button = {
    text: _t(bonusLink ? "productCard.orderNow" : "productCard.check"),
    href: bonusLink || link.href,
    onClick: (!bonusLink && link.onClick) || undefined,
    title: name,
  }

  const price = periodInfo ? (
    <Price
      prefix={prefix}
      periodInfo={periodInfo}
      priceType={currentPriceType}
      periodText={periodText}
      tooltip={tooltip}
    />
  ) : undefined

  const renewPriceObject = renewPrice
    ? {
        label: _t("productCard.renewPrice"),
        value: renewPrice,
        suffix: `${currency} ${suffix}`,
      }
    : undefined

  const hasPromotion = periodInfo?.hasPromotion() ?? false

  return {
    label: hasPromotion
      ? { children: _t("marketplace.discountTitle"), variant: "notifyLight" }
      : undefined,
    backgroundImage,
    title: name,
    logo,
    link,
    description,
    price,
    renewPrice: renewPriceObject,
    priceText,
    parameters,
    button,
  }
}
