import type { FC } from "react"
import React from "react"
import _t from "@core/i18n"
import { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ToggleWithLabelsAtm from "@onestore/hel/dist/components/atoms/ToggleWithLabelsAtm"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import { usePriceTypeContext } from "~/context/PriceTypeContext"

type Props = {
  defaultActiveValue?: PriceType
  onClick?: (PriceType: PriceType) => void
}

const DomainTogglePriceType: FC<Props> = ({
  onClick,
  defaultActiveValue,
}: Props) => {
  const { isNettoPriceType, setPriceType } = usePriceTypeContext()

  const handleToggleSwitch = () => {
    let priceType: PriceType

    if (isNettoPriceType) {
      setPriceType(PriceType.GROSS)

      priceType = PriceType.GROSS
    } else {
      setPriceType(PriceType.NETTO)

      priceType = PriceType.NETTO
    }

    if (onClick) {
      onClick(priceType)
    }
  }

  useIsomorphicLayoutEffect()(() => {
    if (defaultActiveValue === PriceType.NETTO) {
      setPriceType(PriceType.NETTO)
    } else if (defaultActiveValue === PriceType.GROSS) {
      setPriceType(PriceType.GROSS)
    }
  }, [])

  return (
    <WrapOrg gutterSpace={2} alignCenter="vertical">
      <TextAtm typography="nano1">{_t("priceTypeToggleLabel")}</TextAtm>

      <ToggleWithLabelsAtm
        id="toggle-domian-price-type"
        values={[_t("prices.netto"), _t("prices.gross")]}
        activeIndex={isNettoPriceType ? 0 : 1}
        onClick={handleToggleSwitch}
      />
    </WrapOrg>
  )
}

export default DomainTogglePriceType
