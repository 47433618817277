import type { ReactElement } from "react"
import React, { useContext } from "react"
import { typeMatches } from "@onestore-graphql"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaLinkFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import SearchUrlParametersContext from "~/context/SearchUrlParametersContext"
import useModalState from "~/hooks/useModalState"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import CtaModal from "../CtaModal"
import FormLink from "../FormLink"

interface Props {
  link: CtaElement
  typography?: Theme.Typography
}

function UnderlineLink({
  link,
  typography = "small2",
}: Props): ReactElement | null {
  const {
    actionTypeName,
    text,
    linkType,
    title,
    gaEvent,
    anchorId,
    form,
    href,
    openInNewTab,
    pages,
    modalTitle,
    texts,
  } = getCtaLinkFlatData(link)
  const { isOpen, open, close } = useModalState(gaEvent)
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)

  if (isEmpty(actionTypeName)) {
    return null
  }

  const commonProps = {
    typography,
    title,
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.SCROLL_TO_SECTION) &&
    !isEmpty(anchorId)
  ) {
    return (
      <UnderlineLinkAtm
        {...commonProps}
        scrollTo={anchorId}
        onClick={() => sendGaEvent(gaEvent)}
      >
        {text}
      </UnderlineLinkAtm>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.OPEN_FORM) &&
    !isEmpty(form)
  ) {
    return (
      <FormLink {...commonProps} text={text} form={form} linkType={linkType} />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.OPEN_MODAL) &&
    !isEmpty(texts)
  ) {
    return (
      <>
        <UnderlineLinkAtm {...commonProps} onClick={open}>
          {text}
        </UnderlineLinkAtm>

        <CtaModal
          isOpen={isOpen}
          texts={texts}
          title={modalTitle}
          onCloseButtonClick={close}
        />
      </>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.INTERNAL_LINK) &&
    !isEmpty(pages)
  ) {
    return (
      <UnderlineLinkAtm
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        to={pages[0].flatData.url}
      >
        {text}
      </UnderlineLinkAtm>
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.EXTERNAL_LINK) &&
    !isEmpty(href)
  ) {
    return (
      <UnderlineLinkAtm
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        href={replaceLinkValues(href, currentSearchUrlParameters)}
        openInNewTab={openInNewTab}
      >
        {text}
      </UnderlineLinkAtm>
    )
  }

  return null
}

export default UnderlineLink
