import type { ReactElement } from "react"
import React, { useContext } from "react"
import { typeMatches } from "@onestore-graphql"
import FloatingButtonMol from "@onestore/hel/dist/components/molecules/FloatingButtonMol"
import type { Props as FloatingButtonMolProps } from "@onestore/hel/dist/components/molecules/FloatingButtonMol"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import FormilyFormButton from "@gatsby-plugin-generic-page/components/FormilyFormButton"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { FloatingCtaElementSectionFlatData } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import { IconPosition } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import { SquidexFormType } from "@gatsby-plugin-generic-page/types/formType"
import SearchUrlParametersContext from "~/context/SearchUrlParametersContext"
import type { Form } from "~/fragments/form"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import FloatingButtonWithAnchor from "../FloatingButtonWithAnchor"
import FloatingButtonWithForm from "../FloatingButtonWithForm"

type Props = FloatingCtaElementSectionFlatData & {
  isVisible: boolean
}

export type FloatingButtonMolCommonProps = Pick<
  FloatingButtonMolProps,
  "title" | "text" | "isVisible" | "icon"
>

function FloatingCtaElement({
  ctaButton,
  isVisible,
  icon,
  iconPosition,
}: Props): ReactElement<FloatingCtaElementSectionFlatData> | null {
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)

  if (isEmpty(ctaButton)) {
    return null
  }

  const { text, title, gaEvent, anchorId, form, href, openInNewTab, pages } =
    getCtaButtonFlatData(ctaButton[0] as CtaElement)

  const commonProps: FloatingButtonMolCommonProps = {
    icon: !isEmpty(icon) ? (
      <Picture srcData={assetsSquidexToImageObject(icon, "")} />
    ) : null,
    title: title,
    text: text,
    isVisible: isVisible,
  }

  if (anchorId) {
    return (
      <FloatingButtonWithAnchor
        {...commonProps}
        anchorId={anchorId}
        iconPosition={iconPosition}
      />
    )
  }

  if (
    !isEmpty(form) &&
    typeMatches(form[0].__typename, SquidexFormType.FORMILY_FORM)
  ) {
    return (
      <FormilyFormButton
        button={(onClick) => (
          <FloatingButtonMol
            {...commonProps}
            iconPosition={iconPosition ? IconPosition[iconPosition] : undefined}
            onClick={onClick}
          />
        )}
        formilyForm={form[0] as FormilyType}
      />
    )
  }

  if (!isEmpty(form) && typeMatches(form[0].__typename, SquidexFormType.FORM)) {
    return (
      <FloatingButtonWithForm
        {...commonProps}
        iconPosition={iconPosition}
        form={form as Form[]}
      />
    )
  }

  return (
    <FloatingButtonMol
      {...commonProps}
      iconPosition={iconPosition ? IconPosition[iconPosition] : undefined}
      href={replaceLinkValues(href, currentSearchUrlParameters)}
      to={!isEmpty(pages) ? pages[0].flatData.url : undefined}
      onClick={() => sendGaEvent(gaEvent)}
      openInNewTab={openInNewTab}
    />
  )
}

export default FloatingCtaElement
