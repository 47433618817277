import type { ReactElement } from "react"
import React from "react"
import type { NotificationBar } from "~/hooks/useNotificationBarQuery"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import { sendGaEvent } from "~/lib/ga4"
import getPeriodUntillNow from "~/lib/getPeriodUntilNow"
import isEmpty from "~/lib/isEmpty"
import BaseBar from "./BaseBar"
import HideWhenNotInRange from "./HideWhenNotInRange"

export interface BarProps {
  data: NotificationBar["flatData"]
}

function Bar({ data }: BarProps): ReactElement | null {
  const {
    name,
    type,
    link,
    period,
    content,
    textColor,
    backgroundImage,
    modal,
    backgroundColor,
    backgroundType,
    gaEvent,
  } = data

  const getLinkData = () => {
    const { title, pages, anchorId, url: href, openInNewTab } = link[0]
    const onClick = () => sendGaEvent(gaEvent)

    if (pages && pages.length > 0) {
      const { url } = pages[0].flatData
      const to = anchorId ? `${url.replace(/\/$/g, "")}#${anchorId}` : url

      return {
        title,
        to,
        onClick,
        openInNewTab,
      }
    }

    return {
      title,
      href,
      onClick,
      openInNewTab,
    }
  }

  const periodUntillNow = getPeriodUntillNow(period)
  const linkData = !isEmpty(link) ? getLinkData() : undefined
  const modalData = !isEmpty(modal) ? modal[0] : undefined
  const image = !isEmpty(backgroundImage)
    ? assetsSquidexToImageObject(backgroundImage, "")
    : undefined

  return periodUntillNow ? (
    <HideWhenNotInRange from={periodUntillNow.from} to={periodUntillNow.to}>
      <BaseBar
        data={{
          name,
          textColor,
          backgroundColor,
          content,
          modalData,
          linkData,
          backgroundType,
          image: image && { src: image.src, retinaSrc: image.retinaSrc },
        }}
        type={type}
      />
    </HideWhenNotInRange>
  ) : null
}

export default Bar
