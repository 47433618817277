import type { ReactElement, SyntheticEvent } from "react"
import React, { memo, useContext, useMemo } from "react"
import _t from "@core/i18n"
import type { Link, MenuItem } from "@onestore-graphql/Model"
import ThinX from "@onestore-icons/thin-x.svg"
import PageHeaderOrg from "@onestore/hel/dist/components/organisms/PageHeaderOrg"
import type { BarOrgProps } from "@onestore/hel/dist/components/organisms/PageHeaderOrg/components/BarOrg"
import type { NavigationOrgProps } from "@onestore/hel/dist/components/organisms/PageHeaderOrg/components/MainOrg/NavigationOrg"
import type { NavigationDropdownContentOrgProps } from "@onestore/hel/dist/components/organisms/PageHeaderOrg/components/MainOrg/NavigationOrg/NavigationDropdownOrg/NavigationDropdownContentOrg"
import { HeaderContext } from "~/context/HeaderContext"
import HeaderSearchInputContext from "~/context/HeaderSearchInputContext"
import useAccount from "~/hooks/useAccount"
import useBasket from "~/hooks/useBasket"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import {
  sendGaEvent,
  sendMenuViewEvent,
  sendToplayerShowEvent,
} from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"
import { getLinkPropForUrl } from "~/lib/url"
import useMenuQuery from "../../hooks/useMenuQuery"
import useCart from "./useCart"

interface HeaderProps {
  disableCartClick?: boolean
  isDarkMode?: boolean
  hasHideBoxShadow?: boolean
}

function getLinkProps(item: MenuItem | Link): {
  to?: string
  href?: string
} {
  return {
    ...getLinkPropForUrl(item.flatData.url),
  }
}

function mapLinks(
  links: MenuItem[] | undefined
): NavigationDropdownContentOrgProps["links"] {
  if (!links) {
    return []
  }

  return links.map((link) => ({
    title: link.flatData.name,
    subtitle: link.flatData.subtitle,
    linkTitle: link.flatData.urlTitle || link.flatData.name,
    label: link.flatData.label,
    onClick: () => sendGaEvent(link.flatData.gaEvent),
    ...getLinkProps(link),
  }))
}

function mapMenuItems(mainMenuItem: MenuItem) {
  const { name, items, secondaryItems } = mainMenuItem.flatData

  if (items?.length) {
    const dropdown: NavigationDropdownContentOrgProps = {
      title: items[0].flatData.name,
      links: mapLinks(items[0].flatData.items),
      additionalLink:
        items && items.length > 1 && items[1].flatData.url
          ? {
              title: items[1].flatData.name,
              text: items[1].flatData.name,
              ...getLinkProps(items[1]),
            }
          : undefined,
      asideLinks: secondaryItems?.length ? mapLinks(secondaryItems) : undefined,
    }

    return {
      cta: {
        text: name,
      },
      dropdown,
    }
  }

  return {
    cta: {
      text: name,
      title: name,
      ...getLinkProps(mainMenuItem),
    },
  }
}

function Header({
  disableCartClick = false,
  isDarkMode = false,
  hasHideBoxShadow = false,
}: HeaderProps): ReactElement {
  const { menu } = useMenuQuery()
  const cart = useCart(disableCartClick, menu.flatData.gaCartEvent)
  const {
    headerData: { logo },
  } = useSiteMetadataQuery()
  const logoDarkMode = isDarkMode ? menu.flatData.logoDarkMode : undefined

  const { account, logout } = useAccount()
  const { getBasket } = useBasket()

  const context = useContext(HeaderContext)

  const onHeaderLoad = (headerHeight) => {
    context?.setHeaderInitHeight(headerHeight)
  }

  const onHeaderVisibilityChange = (isVisible, headerHeight) => {
    if (isVisible) {
      context?.setHeaderHeight(headerHeight)
    } else {
      context?.setHeaderHeight(0)
    }
  }

  const {
    placeholder,
    inputValue,
    onChange,
    onKeyPress,
    hasMobileSearchInput,
    resetInputValue,
  } = useContext(HeaderSearchInputContext)

  const handleClick = (event: SyntheticEvent): void => {
    event.preventDefault()

    logout(getBasket)
  }

  const asideLinks = useMemo(
    () =>
      menu.flatData.links.map((link: Link) => ({
        text: link.flatData.title,
        title: link.flatData.title,
        openInNewTab: true,
        isHiddenOnMobile: link.flatData.hiddenOnMobile,
        onClick: () => sendGaEvent(link.flatData.gaEvent),
        ...getLinkProps(link),
      })),
    []
  )

  const navigation: NavigationOrgProps["navigation"] = useMemo(
    () => menu.flatData.navigation.map(mapMenuItems),
    []
  )

  const accountData: BarOrgProps["account"] = account
    ? {
        name: account.first_name,
        type: account.type,
        dropdown: {
          title:
            account.type === "person"
              ? `${account.first_name} ${account.last_name}`
              : account.company_name || "",
          subtitle: `${_t("account_id")} ${account.id}`,
          asideLinks: [
            {
              text: _t("logout"),
              href: "#",
              onClick: handleClick,
              title: _t("logout"),
            },
          ],
        },
      }
    : undefined

  const ctaIcon = inputValue
    ? { icon: ThinX, onClick: resetInputValue }
    : undefined

  return (
    <PageHeaderOrg
      barLinks={asideLinks}
      account={accountData}
      logo={{
        ...getLinkProps(menu),
        title: menu.flatData.siteName,
        src: !isEmpty(logoDarkMode)
          ? logoDarkMode[0].url
          : menu.flatData.logo[0].url,
        alt: `Logo ${menu.flatData.siteName}`,
        width: !isEmpty(logoDarkMode) ? logoDarkMode[0].pixelWidth : logo.width,
        height: !isEmpty(logoDarkMode)
          ? logoDarkMode[0].pixelHeight
          : logo.height,
        to: "/",
        onClick: () => sendGaEvent(menu.flatData.gaLogoEvent),
      }}
      navigation={navigation}
      cart={cart}
      searchInput={
        hasMobileSearchInput
          ? {
              placeholder,
              value: inputValue,
              onChange,
              onKeyPress,
              ctaIcon,
            }
          : undefined
      }
      mobileNavigationBackLinkText={_t("backToMenu")}
      onHeaderLoad={onHeaderLoad}
      onHeaderVisibilityChange={onHeaderVisibilityChange}
      hamburgerOnClick={() => {
        sendMenuViewEvent()
        sendToplayerShowEvent()
      }}
      isDarkMode={isDarkMode}
      hasHideBoxShadow={hasHideBoxShadow}
    />
  )
}

export default memo(Header)
