import type { ReactElement } from "react"
import React from "react"
import { getFormattedPeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import { typeMatches } from "@onestore-graphql"
import ImageWithTextLinkAtm from "@onestore/hel/dist/components/atoms/ImageWithTextLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ItemPriceLinkMol from "@onestore/hel/dist/components/molecules/ItemPriceLinkMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import HorizontalScrollItemsOrg from "@onestore/hel/dist/components/organisms/HorizontalScrollItemsOrg"
import type { DomainLink } from "@gatsby-plugin-generic-page/fragments/domainLink"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import type { Caption, CaptionIcon } from "~/types/squidex"

type DomainItemsProps = {
  domainLinks: DomainLink[]
  fullWidthOnLarge: boolean
}

function DomainItems({
  domainLinks,
  fullWidthOnLarge,
}: DomainItemsProps): ReactElement<DomainItemsProps> {
  const { isNettoPriceType } = usePriceTypeContext()

  return (
    <AlignContentOrg
      horizontalAlign={{
        small: "default",
        medium: "center",
        large: "center",
      }}
    >
      <HorizontalScrollItemsOrg
        gutterSpace={1}
        topSpace={{ small: 4, medium: 3.5, large: 2 }}
        bottomSpace={1}
        justifyContent="space-between"
        isFullWidthOnLarge={fullWidthOnLarge}
      >
        {domainLinks.map((domainLink, id) => {
          const { variantType, cloudBlueDomain, period, label, link } =
            domainLink

          const price = getFormattedPeriodInfo(
            cloudBlueDomain[0].flatData.periods,
            period,
            isNettoPriceType
              ? PriceFormat.REGISTER_NETTO_NO_PERIOD
              : PriceFormat.REGISTER_GROSS_NO_PERIOD
          )

          const periodName = getFormattedPeriodInfo(
            cloudBlueDomain[0].flatData.periods,
            period,
            isNettoPriceType
              ? PriceFormat.NETTO_WITH_PERIOD
              : PriceFormat.GROSS_WITH_PERIOD
          )

          const domainExtension = `${cloudBlueDomain[0].flatData.extension}-${id}`

          const { to, href, title, openInNewTab, onClick } = getLinkProps(link)

          if (
            typeMatches(
              variantType.__typename,
              SquidexComponentType.CAPTION_ICON
            )
          ) {
            const { captionIcon } = variantType as CaptionIcon

            return (
              <ImageWithTextLinkAtm
                key={domainExtension}
                image={assetsSquidexToImageObject(captionIcon, domainExtension)}
                label={label}
                link={{ to, href, title, openInNewTab, onClick }}
                text={
                  <TextAtm typography="xsmall2" emphasis="high" isBold>
                    {price}

                    <TextAtm typography="xsmall2" emphasis="low">
                      {" "}
                      {periodName}
                    </TextAtm>
                  </TextAtm>
                }
              />
            )
          }

          if (
            typeMatches(variantType.__typename, SquidexComponentType.CAPTION)
          ) {
            const { text } = variantType as Caption

            return (
              <ItemPriceLinkMol
                key={domainExtension}
                text={text}
                price={price}
                link={{ to, href, title, openInNewTab, onClick }}
              />
            )
          }

          return null
        })}
      </HorizontalScrollItemsOrg>
    </AlignContentOrg>
  )
}

export default DomainItems
