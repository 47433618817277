import type { ReactElement } from "react"
import React from "react"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import DomainSearchInputTabs from "@gatsby-plugin-domain-search/components/DomainSearchInputTabs"
import DomainSearchPrompt from "@gatsby-plugin-domain-search/components/DomainSearchPrompt"
import useDomainSearchPromptInput from "@gatsby-plugin-domain-search/hooks/useDomainSearchPromptInput"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { SearchSectionFlatData } from "@gatsby-plugin-generic-page/fragments/searchSection"
import DomainSearch from "./components/DomainSearch"

function MainSearchSection({
  text,
  sectionSettings,
}: SearchSectionFlatData): ReactElement<SearchSectionFlatData> {
  const {
    value,
    setValueToRandomPrompt,
    setValue,
    onSubmitButtonClick,
    isPending,
  } = useDomainSearchPromptInput()

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <DomainSearchInputTabs
          showOnTop={true}
          activeTabIndex={0}
          backgroundColor="background"
          domainSearchInput={
            <>
              <BreakpointVisibilityOrg from="large">
                <FlexContainerOrg alignItems={!!text ? "flex-start" : "center"}>
                  <DomainSearch text={text} />
                </FlexContainerOrg>
              </BreakpointVisibilityOrg>

              <BreakpointVisibilityOrg from="small" to="large">
                <DomainSearch text={text} />
              </BreakpointVisibilityOrg>
            </>
          }
          domainSearchPrompt={
            <DomainSearchPrompt
              value={value}
              isPending={isPending}
              setValue={setValue}
              onSubmitButtonClick={onSubmitButtonClick}
              onRandomizePrompt={setValueToRandomPrompt}
            />
          }
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default MainSearchSection
