import type { ReactElement } from "react"
import React, { useContext } from "react"
import _t from "@core/i18n"
import type { ImageTileLink as ImageTileLinkData } from "@onestore-graphql/Model"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ImageTileLinkMol from "@onestore/hel/dist/components/molecules/ImageTileLinkMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import SearchUrlParametersContext from "~/context/SearchUrlParametersContext"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

interface ImageTileLinkProps {
  imageTileLink: ImageTileLinkData
  imageTileLinkIndex: number
}
function ImageTileLink({
  imageTileLink,
  imageTileLinkIndex,
}: ImageTileLinkProps): ReactElement<ImageTileLinkProps> | null {
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)
  const isSmallScreen = useBreakpoint("small", "medium")
  const { text, image, ctaElement } = imageTileLink.flatData

  if (isEmpty(ctaElement)) {
    return null
  }

  const hasMargin = imageTileLinkIndex % 2 !== 0

  const {
    text: ctaElementText,
    title,
    variant,
    buttonColor,
    gaEvent,
    href,
    openInNewTab,
    pages,
  } = getCtaButtonFlatData(ctaElement[0])

  return (
    <ConditionalWrapper
      key={imageTileLink.id}
      condition={hasMargin && !isSmallScreen}
      wrapper={(children) => <PushOrg topSpace={8}>{children}</PushOrg>}
    >
      <FlexContainerOrg direction="column" alignItems="center">
        <div>
          <ImageTileLinkMol
            image={assetsSquidexToImageObject(image, "")}
            button={{
              text: ctaElementText || _t("show"),
              title,
              href: replaceLinkValues(href, currentSearchUrlParameters),
              to: !isEmpty(pages) ? pages[0].flatData.url : undefined,
              openInNewTab,
              variant,
              color: buttonColor.color,
              onClick: () => sendGaEvent(gaEvent),
            }}
          />

          {text ? (
            <PushOrg topSpace={2.5}>
              <TextAtm typography="medium1_h">{text}</TextAtm>
            </PushOrg>
          ) : null}
        </div>
      </FlexContainerOrg>
    </ConditionalWrapper>
  )
}

export default ImageTileLink
