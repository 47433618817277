import type { ReactElement } from "react"
import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import { get } from "lodash"
import SelectButtonAtm from "@onestore/hel/dist/components/atoms/SelectButtonAtm"
import TabsLinkMol from "@onestore/hel/dist/components/molecules/TabsLinkMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import HorizontalScrollItemsOrg from "@onestore/hel/dist/components/organisms/HorizontalScrollItemsOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { TabsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/tabsSection"
import { GenericPageSectionsRenderer } from "@gatsby-plugin-generic-page/GenericPage"
import { useSessionStorage } from "~/hooks/useSessionStorage"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

export default function TabsSection({
  id,
  header,
  isCentered,
  hasButtonsAsLinks,
  hasSmallBottomSpace,
  tabs,
  sectionSettings,
}: TabsSectionFlatData): ReactElement<TabsSectionFlatData> {
  const tabsWithSection = tabs.filter((tab) => !isEmpty(tab.section))
  const [activeButton, setActiveButton] = useSessionStorage<string>(
    `activeTab_${id}`,
    tabsWithSection[0].id
  )

  const { hash } = useLocation()
  const backgroundColor = sectionSettings?.color
  const hasBlackBackgroundColor = backgroundColor
    ? ["black"].indexOf(backgroundColor) !== -1
    : false

  useEffect(() => {
    if (hash) {
      const locationHash = hash.replace("#", "")
      const tab = tabsWithSection.find((tab) => tab.id === locationHash)

      if (tab) {
        setActiveButton(tab.id)
      }
    }
  }, [hash])

  return (
    <>
      <BaseSection {...sectionSettings} hasOverflowHidden>
        <BaseContainer {...sectionSettings?.containerSettings}>
          {header ? (
            <PushOrg
              bottomSpace={hasSmallBottomSpace ? 4 : 8}
              leftSpace={{ small: 2, medium: 2, large: 0 }}
              rightSpace={{ small: 2, medium: 2, large: 0 }}
              hasNoReset
            >
              <AlignContentOrg
                horizontalAlign={{
                  small: get(header, "align.small.horizontalAlign", "left"),
                  medium: get(header, "align.medium.horizontalAlign", "center"),
                  large: get(header, "align.large.horizontalAlign", "center"),
                }}
              >
                <Header {...header} />
              </AlignContentOrg>
            </PushOrg>
          ) : null}
          <FlexContainerOrg
            justifyContent={isCentered ? "center" : "flex-start"}
          >
            <HorizontalScrollItemsOrg gutterSpace={2} bottomSpace={1}>
              {hasButtonsAsLinks ? (
                <TabsLinkMol
                  tabs={tabsWithSection.map((tab) => ({
                    onClick: () => {
                      sendGaEvent(tab.gaEvent)

                      setActiveButton(tab.id)
                    },
                    text: tab.buttonText,
                    isActive: activeButton === tab.id,
                  }))}
                  gutterSpace={3}
                />
              ) : (
                <>
                  {tabs.map((tab, index) => (
                    <SelectButtonAtm
                      activeColor={
                        hasBlackBackgroundColor ? "customLight" : undefined
                      }
                      key={`${tab.buttonText}-${index}`}
                      title={tab.buttonText}
                      onClick={() => {
                        sendGaEvent(tab.gaEvent)

                        setActiveButton(tab.id)
                      }}
                      isActive={activeButton === tab.id}
                    >
                      {tab.buttonText}
                    </SelectButtonAtm>
                  ))}
                </>
              )}
            </HorizontalScrollItemsOrg>
          </FlexContainerOrg>
        </BaseContainer>
      </BaseSection>

      <GenericPageSectionsRenderer
        pageId={activeButton}
        sections={tabs.find((tab) => tab.id === activeButton)?.section || []}
      />
    </>
  )
}
