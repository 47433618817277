import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PackageBoxesSlimOrg from "@onestore/hel/dist/components/organisms/PackageBoxesSlimOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import InfoBar from "@gatsby-plugin-generic-page/components/InfoBar"
import ProductBoxSlim from "@gatsby-plugin-generic-page/components/ProductBoxSlim"
import type { ProductBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/productBoxSection"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"
import ProductBoxPeriodsSwitcherSection from "./ProductBoxPeriodsSwitcherSection"

export default function ProductBoxSection({
  boxes,
  sectionSettings,
  subtitle,
  title,
  ctaElement,
  hasPeriodsSwitcher,
  periodsSwitcher,
  hasTogglePriceType,
  hasStretchedChildren,
  infoBar,
}: ProductBoxSectionFlatData): ReactElement<ProductBoxSectionFlatData> {
  const boxesSpace = boxes.length > 2 ? 170 : 105
  const isSmallAndMediumScreen = useBreakpoint("small", "large")

  if (hasPeriodsSwitcher && periodsSwitcher) {
    return (
      <ProductBoxPeriodsSwitcherSection
        {...{
          boxes,
          sectionSettings,
          subtitle,
          title,
          ctaElement,
          periodsSwitcher,
          hasTogglePriceType,
          hasStretchedChildren,
          infoBar,
        }}
      />
    )
  }

  const promoTextIndexes = boxes
    .filter(
      (box) =>
        box.flatData.buttons[0].promoText &&
        box.flatData.buttons[0].color === "primary"
    )
    .map((box) => boxes.indexOf(box))

  return (
    <BaseSection
      {...sectionSettings}
      hasOverflowHidden={isSmallAndMediumScreen}
    >
      <BaseContainer {...sectionSettings?.containerSettings}>
        {title ? (
          <PushOrg bottomSpace={hasTogglePriceType ? 4 : 8}>
            <AlignContentOrg
              horizontalAlign="center"
              horizontalAlignType="flex"
            >
              <Header title={title} text={subtitle} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        {hasTogglePriceType ? (
          <PushOrg bottomSpace={{ small: 2, medium: 3, large: 4.5 }}>
            <AlignContentOrg horizontalAlign="right">
              <TogglePriceType />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <AlignContentOrg horizontalAlign="center" horizontalAlignType="flex">
          <WidthOrg widthSpace={boxesSpace}>
            <PackageBoxesSlimOrg
              hasStretchedChildren={hasStretchedChildren}
              promoTextIndexes={promoTextIndexes}
            >
              {boxes.map((box, index) => (
                <ProductBoxSlim key={box.id} index={index} productBox={box} />
              ))}
            </PackageBoxesSlimOrg>
          </WidthOrg>
        </AlignContentOrg>

        {!isEmpty(ctaElement) && (
          <PushOrg topSpace={6}>
            <AlignContentOrg horizontalAlign="center">
              <CtaElement ctaElement={ctaElement} />
            </AlignContentOrg>
          </PushOrg>
        )}

        {!isEmpty(infoBar) && (
          <PushOrg topSpace={5}>
            <AlignContentOrg horizontalAlign="center">
              <WidthOrg widthSpace={120}>
                <InfoBar {...infoBar[0]} />
              </WidthOrg>
            </AlignContentOrg>
          </PushOrg>
        )}
      </BaseContainer>
    </BaseSection>
  )
}
