import type { ReactElement } from "react"
import React from "react"
import CategoryCardMol from "@onestore/hel/dist/components/molecules/CategoryCardMol"
import PromoInfo from "@onestore/hel/dist/components/molecules/CategoryCardMol/PromoInfo"
import PromoPrefix from "@onestore/hel/dist/components/molecules/CategoryCardMol/PromoPrefix"
import Title from "@onestore/hel/dist/components/molecules/CategoryCardMol/Title"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import type { CategoryCardData } from "@gatsby-plugin-generic-page/fragments/categoryCard"
import useSectionsModal from "@gatsby-plugin-generic-page/hooks/useSectionsModal"
import ModalContainer from "~/components/ModalContainer"
import Media from "../Media"
import SideModal from "../SideModal"

type Props = {
  card: CategoryCardData
  sectionBackgroundColor: string
}

function CategoryCard({
  card,
  sectionBackgroundColor,
}: Props): ReactElement<Props> {
  const { title, caption, text, responsiveImages, backgroundColor, sideModal } =
    card
  const { isOpen, handleOpenOverlay, handleCloseOverlay } = useSectionsModal()

  return (
    <>
      <CategoryCardMol
        image={<Media media={responsiveImages} />}
        backTileColor={backgroundColor.color}
        title={
          <BaseMarkdown
            options={{
              wrapper: ({ children }) => <Title>{children}</Title>,
              forceWrapper: true,
            }}
          >
            {title}
          </BaseMarkdown>
        }
        promoPrefix={<PromoPrefix>{caption}</PromoPrefix>}
        promoInfo={<PromoInfo>{text}</PromoInfo>}
        onClick={(event) => handleOpenOverlay(event, title)}
        backgroundColor={sectionBackgroundColor}
        hasImageStuckToRight
      />

      <ModalContainer>
        <SideModal
          isOpen={isOpen}
          onCloseButtonClick={handleCloseOverlay}
          categoryName={title}
          {...sideModal[0].flatData}
        />
      </ModalContainer>
    </>
  )
}

export default CategoryCard
