import _t from "@core/i18n"
import {
  applyTaxRate,
  CURRENCY_CODE,
  CURRENCY_SEPARATOR,
  formatPrice,
  formatPriceObjectCents,
  formatPriceObjectDecimals,
  hasNoTaxRate,
  formatted,
  normalizePrice,
  getCurrencySign,
  getPriceObject,
  applyDefaultTax,
} from "@core/pricing"
import type { FormattedPriceInfo } from "@core/types"
import { PriceType } from "@core/types"
import type { BasketResponseItem, PriceValue } from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { RemoteProduct } from "@onestore/api/product"
import type {
  PeriodName,
  PeriodType,
  PeriodValue,
  Price,
  ProductPriceDefinition,
  TaxRate,
} from "@onestore/api/types"
import type { BonusPeriod } from "@gatsby-plugin-bonus/types"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"

export interface BasketItemPrice {
  price: number
  quantity: number
}

export interface BundledPrice {
  discount: number | null
  periodValue: PeriodValue | null
  periodType: PeriodType | null
  periodName: PeriodName | null
  currency: string
  primaryTypeRenewPrice: FormattedPriceInfo | undefined
  secondaryTypeRenewPrice: FormattedPriceInfo | undefined
  primaryTypeRegularPrice: FormattedPriceInfo
  secondaryTypeRegularPrice: FormattedPriceInfo
  primaryTypePromoPrice: FormattedPriceInfo | undefined
  secondaryTypePromoPrice: FormattedPriceInfo | undefined
}

export const getPriceObjectAsFloat = (
  priceObject: Price,
  type: PriceType = PriceType.GROSS
): string => `${normalizePrice(priceObject[type])}`

/**
 * Funkcja formatująca podaną cenę z uwzględnieniem stawki podatku oraz domyślnej waluty.
 *
 * @param   {number} price Cena do której zostanie dodany podatek
 * @param   {number} taxRate Stawka podatku VAT która zostanie doliczona
 * @returns {string}
 */
export const formatPriceWithTaxRate = (
  price: number | null,
  taxRate: TaxRate
): string => {
  if (price === null) {
    return ""
  }

  return formatPrice(applyTaxRate(price, taxRate))
}

export const getBundlePricesFromDefinitionForType = (
  price: ProductPriceDefinition,
  priceType: PriceType
) => {
  const primaryPriceType =
    priceType === PriceType.NETTO ? PriceType.NETTO : PriceType.GROSS
  const secondaryPriceType =
    priceType === PriceType.NETTO ? PriceType.NETTO : PriceType.GROSS

  return {
    periodValue: price.periodValue,
    periodType: price.periodType,
    periodName: price.periodName,
    discount: price.discount,
    currency: price.regularPrice.currency,
    primaryTypeRegularPrice: formatted(price.regularPrice, primaryPriceType),
    primaryTypePromoPrice: price.promoPrice
      ? formatted(price.promoPrice, primaryPriceType)
      : undefined,
    secondaryTypePromoPrice: price.promoPrice
      ? formatted(price.promoPrice, secondaryPriceType)
      : undefined,
    secondaryTypeRegularPrice: formatted(
      price.regularPrice,
      secondaryPriceType
    ),
    primaryTypeRenewPrice: price.renewalPrice
      ? formatted(price.renewalPrice, primaryPriceType)
      : undefined,
    secondaryTypeRenewPrice: price.renewalPrice
      ? formatted(price.renewalPrice, secondaryPriceType)
      : undefined,
  }
}

export const formatPriceWithDefaultCurrency = (
  price: PriceValue | null,
  disableTax: boolean = false
): string => {
  if (price === null) {
    return ""
  }

  const taxedPrice = disableTax ? price : applyDefaultTax(price)

  return formatPrice(taxedPrice, getCurrencySign())
}

export const formatPriceWithCurrency = (
  price: number | null,
  disableTax: boolean = false
): string => {
  if (price === null) {
    return ""
  }

  const taxedPrice = disableTax ? price : applyDefaultTax(price)

  return formatPrice(taxedPrice, CURRENCY_CODE)
}

/**
 * Wylicza jednostkową cenę pozycji koszyka.
 *
 * @param {BasketItem} item
 * @returns {number}
 */
const getBasketItemPricePerUnit = (item: BasketItemPrice): number => {
  return item.price / item.quantity
}

/**
 * Zwraca jednostkową cenę pozycji koszyka po sformatowaniu.
 *
 * @param   {BasketItem} item
 * @returns {number}
 */
export const getBasketItemPricePerUnitFormatted = (
  item: BasketItemPrice
): number => {
  return parseFloat(normalizePrice(getBasketItemPricePerUnit(item)))
}

export const applyDefaultTaxRate = (price: number): number =>
  applyDefaultTax(price)

export interface ProductPriceNested {
  price: Price
  regularPrice: Price
  renewPrice: Price | null
  period: PeriodName
  priceValue: string
  lowestPrice: string
  lowestPricePercent: string
  hasPromotion: boolean
}

export interface ProductPricing {
  priceSubtext: string
  priceCents: string
  priceDecimals: string
  regularPriceCents: string
  regularPriceDecimals: string
  renewPriceCents: string
  renewPriceDecimals: string
  currency: string
  currencySeparator: string
  taxRate: number
}

export const getProductPricing = (
  priceValues: Pick<
    ProductPriceNested,
    "price" | "period" | "regularPrice" | "renewPrice"
  >,
  priceType: PriceType
): ProductPricing => {
  return {
    priceSubtext: `${
      hasNoTaxRate() ? _t("prices.netto") : _t(`prices.${priceType}`)
    } ${priceValues.period ? ` / ${priceValues.period}` : ""}`,
    priceCents: formatPriceObjectCents(priceValues.price, priceType),
    priceDecimals: formatPriceObjectDecimals(priceValues.price, priceType),
    regularPriceCents: formatPriceObjectCents(
      priceValues.regularPrice,
      priceType
    ),
    regularPriceDecimals: formatPriceObjectDecimals(
      priceValues.regularPrice,
      priceType
    ),
    renewPriceCents:
      priceValues.renewPrice === null
        ? ""
        : formatPriceObjectCents(priceValues.renewPrice, priceType),
    renewPriceDecimals:
      priceValues.renewPrice === null
        ? ""
        : formatPriceObjectDecimals(priceValues.renewPrice, priceType),
    currency: CURRENCY_CODE,
    currencySeparator: CURRENCY_SEPARATOR,
    taxRate: priceValues.price.taxRate,
  }
}

export function updatePeriodPrice(
  periodToUpdate: BonusPeriod,
  item: BasketResponseItem,
  quantityDivider: number
): BonusPeriod {
  const updatedPeriod: BonusPeriod = Object.assign({}, periodToUpdate, {
    price: { regularPrice: null, promoPrice: null },
  })
  let regularPrice = item.regular_price
  let { price } = item

  if (item.resources) {
    item.resources.forEach((resource) => {
      regularPrice += resource.regular_price
      price += resource.price
    })
  }

  if (regularPrice > price) {
    updatedPeriod.price.regularPrice = getPriceObject(
      regularPrice,
      quantityDivider,
      periodToUpdate.tax_rate
    )
    updatedPeriod.price.promoPrice = getPriceObject(
      price,
      quantityDivider,
      periodToUpdate.tax_rate
    )
  } else {
    updatedPeriod.price.regularPrice = getPriceObject(
      price,
      quantityDivider,
      periodToUpdate.tax_rate
    )
  }

  return updatedPeriod
}

/**
 * @deprecated
 */
export function getPriceWithResourceRatesLegacy(
  productDefinition: RemoteProduct,
  selectedPeriod: CloudBluePeriod,
  priceName: "price_register" | "price_no_promo" | "price_renew"
): number {
  let registerPriceWithResourceRates = 0

  if (productDefinition.has_quantity) {
    const quantityResourceData = (selectedPeriod.resource_data ?? []).find(
      (resource) =>
        resource.resource_id === productDefinition.quantity_resource?.id
    )

    if (!quantityResourceData) {
      return 0
    }

    return quantityResourceData.price
  }

  if (productDefinition.advanced_configuration) {
    const { resource_data } = selectedPeriod
    const priceByName = selectedPeriod[priceName]

    if (resource_data) {
      const price = resource_data.reduce(
        (sum, { price, lower_limit, included_value }) => {
          if (lower_limit > 0 && included_value === 0) {
            return sum + price * lower_limit
          }

          return 0
        },
        0
      )

      registerPriceWithResourceRates = priceByName + price
    }
  }

  return registerPriceWithResourceRates
}

export const getSumOfDomainsPrices = (
  domains: DomainCheck.Result[],
  isGrossResult?: boolean
): number =>
  domains.reduce((sumPrices, domain) => {
    if (!domain.period) {
      return sumPrices
    }

    return isGrossResult
      ? sumPrices +
          applyTaxRate(domain.period.price_register, domain.period.tax_rate)
      : sumPrices + domain.period.price_register
  }, 0)
