import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import TickCircle from "@onestore-icons/tick-circle.svg"
import IconAtm from "@onestore/hel/dist/components/atoms/IconAtm"
import { getCtaButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import type { ProductBox } from "@gatsby-plugin-generic-page/types/productBoxTypes"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { Form } from "~/fragments/form"
import type { ProductBoxSlimHookProps } from "~/hooks/useProductBoxSlim"
import isEmpty from "~/lib/isEmpty"
import type { PeriodName } from "../../../../../lib/api/types"
import MarkdownText from "../Markdown/Text"
import productBoxContent from "./productBoxContent"
import ProductBoxOuterFooter from "./ProductBoxOuterFooter"
import ProductBoxWithCta from "./ProductBoxWithCta"
import ProductBoxWithCtaAnchor from "./ProductBoxWithCtaAnchor"
import ProductBoxWithCtaForm from "./ProductBoxWithCtaForm"
import ProductBoxWithSaleConfiguration from "./ProductBoxWithSaleConfiguration"

export type ProductBoxSlimProps = {
  productBox: ProductBox
  period?: PeriodName
  index?: number
}

export default function ProductBoxSlim({
  productBox,
  period,
  index,
}: ProductBoxSlimProps): ReactElement<ProductBoxSlimProps> {
  const {
    buttons,
    label,
    labelVariant,
    parameters,
    descriptionText,
    outerFooter,
    subtitle,
    title,
    showMonthlyPrices,
    hasDarkMode,
    isTrialBox,
  } = productBox.flatData

  const {
    saleConfiguration,
    bundle,
    cta,
    type,
    staticPrices,
    color,
    promoText,
    hideRenewalPrice,
    additionalText,
    planInputLabel,
    changePeriodModal,
  } = buttons[0]

  const content = productBoxContent(
    parameters,
    type,
    bundle,
    isTrialBox,
    <IconAtm icon={TickCircle} sizeSpace={2} color="success" />
  )

  const descriptionContent = descriptionText ? (
    <MarkdownText>{descriptionText}</MarkdownText>
  ) : undefined

  const { currentPriceType } = usePriceTypeContext()

  const product: Omit<ProductBoxSlimHookProps, "plan"> = {
    id: productBox.id,
    promoText: color === "primary" ? promoText : undefined,
    discountLabel: !isTrialBox ? label : undefined,
    discountLabelVariant: !isTrialBox ? labelVariant : undefined,
    content,
    title,
    subtitle,
    description: descriptionContent,
    priceType: !isTrialBox ? currentPriceType : undefined,
    period,
    buttonColor: color,
    hideRenewalPrices: !isTrialBox ? hideRenewalPrice : undefined,
    additionalText: !isTrialBox ? additionalText : undefined,
    outerFooter: !isEmpty(outerFooter) && (
      <ProductBoxOuterFooter outerFooter={outerFooter} />
    ),
    showMonthlyPrices: !isTrialBox ? showMonthlyPrices : undefined,
    hasDarkMode,
    buttonText: productBox.flatData.buttonText,
    backgroundImage: productBox.flatData.background,
  }

  if (typeMatches(type, "plan") && !isEmpty(saleConfiguration)) {
    if (isEmpty(saleConfiguration[0].flatData.defaultCloudBluePlan)) {
      return (
        <ProductBoxWithCta
          product={product}
          cta={cta}
          staticPrices={staticPrices}
          isTrialBox={isTrialBox}
          isDisabled
        />
      )
    }

    return (
      <ProductBoxWithSaleConfiguration
        product={product}
        bundle={bundle}
        saleConfiguration={saleConfiguration}
        planInputLabel={planInputLabel}
        changePeriodModal={
          !isEmpty(changePeriodModal)
            ? changePeriodModal[0].flatData
            : undefined
        }
        index={index}
        isTrialBox={isTrialBox}
      />
    )
  }

  if (typeMatches(type, "link") && !isEmpty(cta)) {
    const { actionTypeName, anchorId, form } = getCtaButtonFlatData(cta[0])

    if (
      actionTypeName &&
      typeMatches(actionTypeName, SquidexComponentType.OPEN_FORM) &&
      !isEmpty(form)
    ) {
      return (
        <ProductBoxWithCtaForm
          product={product}
          form={form as Form[]}
          cta={cta}
          staticPrices={staticPrices}
        />
      )
    }

    if (
      actionTypeName &&
      typeMatches(actionTypeName, SquidexComponentType.SCROLL_TO_SECTION) &&
      !isEmpty(anchorId)
    ) {
      return (
        <ProductBoxWithCtaAnchor
          product={product}
          anchorId={anchorId}
          cta={cta}
          staticPrices={staticPrices}
        />
      )
    }
  }

  return (
    <ProductBoxWithCta
      product={product}
      cta={cta}
      staticPrices={staticPrices}
      isTrialBox={isTrialBox}
    />
  )
}
