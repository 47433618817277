import type { ReactNode, ReactElement } from "react"
import React, { memo } from "react"
import ParallaxWrapperOrg from "@onestore/hel/dist/components/organisms/ParallaxWrapperOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import Header from "@gatsby-plugin-onestore/components/Header"
import ErrorBoundary from "~/components/ErrorBoundary"
import FooterTemplate from "~/components/FooterTemplate"
import ModalHandler from "~/components/ModalHandler"
import Notification from "~/components/Notification"
import NotificationBar from "~/components/NotificationBar"
import { HeaderContextProvider } from "~/context/HeaderContext"
import { HeaderSearchInputContextProvider } from "~/context/HeaderSearchInputContext"
import { PriceTypeContextProvider } from "~/context/PriceTypeContext"
import UserdataContext from "../context/UserdataContext"

interface BaseProps {
  children: ReactNode
  pageContext?: {
    isBasket?: boolean
    isBonus?: boolean
    hasDarkHeader?: boolean
    hasHeaderHideBoxShadow?: boolean
    hasHiddenFooter?: boolean
    isMarketplace?: boolean
  }
  hasParallaxWrapper?: boolean
}

function Base({
  children,
  pageContext,
  hasParallaxWrapper = false,
}: BaseProps): ReactElement<BaseProps> {
  const isBasket = pageContext?.isBasket || false
  const isBonus = pageContext?.isBonus || false
  const hasDarkHeader = pageContext?.hasDarkHeader || false
  const hasHeaderHideBoxShadow = pageContext?.hasHeaderHideBoxShadow || false
  const hasHiddenFooter = pageContext?.hasHiddenFooter || false
  const isMarketplace = pageContext?.isMarketplace || false

  return (
    <ErrorBoundary>
      <ConditionalWrapper
        condition={hasParallaxWrapper}
        wrapper={(children) => (
          <ParallaxWrapperOrg hasFullHeight>{children}</ParallaxWrapperOrg>
        )}
      >
        <UserdataContext>
          <div id="pageNotificationBars">
            <Notification />

            <NotificationBar location="productPage" />
          </div>

          <HeaderContextProvider>
            <HeaderSearchInputContextProvider>
              <Header
                disableCartClick={isBasket}
                isDarkMode={hasDarkHeader}
                hasHideBoxShadow={hasHeaderHideBoxShadow}
              />

              <main>
                <PriceTypeContextProvider isMarketplace={isMarketplace}>
                  {children}
                </PriceTypeContextProvider>
              </main>
            </HeaderSearchInputContextProvider>
          </HeaderContextProvider>

          <ModalHandler />

          {!hasHiddenFooter ? (
            <FooterTemplate hideMobileFooter={isBasket || isBonus} />
          ) : null}
        </UserdataContext>
      </ConditionalWrapper>
    </ErrorBoundary>
  )
}

export default memo(Base)
