import type { ReactElement } from "react"
import React, { useState } from "react"
import _t from "@core/i18n"
import { sortBy } from "lodash"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import ContainerOrg from "@onestore/hel/dist/components/organisms/ContainerOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import OverlayContainerOrg, {
  sizes,
  startPositions,
} from "@onestore/hel/dist/components/organisms/OverlayContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import EmbeddedDomainSearch from "@gatsby-plugin-domain-search/components/EmbeddedDomainSearch"
import type { DomainButtonWithPrice as DomainButtonWithPricesProps } from "@gatsby-plugin-generic-page/fragments/domainButtonWithPrice"
import { sendToplayerShowEvent, sendGaEvent } from "~/lib/ga4"
import ProductPrice from "../ProductPrice"

function DomainButtonWithPrice({
  domains,
  header,
  text,
  hidePrice,
  title,
  buttonColor,
  buttonVariant,
  gaEvent,
}: DomainButtonWithPricesProps["flatData"]): ReactElement<DomainButtonWithPricesProps> | null {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenOverlay = () => {
    sendToplayerShowEvent()
    setIsOpen((value) => !value)
  }

  const sortedDomains = sortBy(domains, "flatData.periods.price_register")

  const priceElement = (
    <FlexContainerOrg alignItems="baseline">
      <PushOrg rightSpace={0.5}>
        <BreakpointVisibilityOrg from="medium">
          <TextAtm typography="nano2" emphasis="medium">
            {_t("prices.from")}
          </TextAtm>
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="small" to="medium">
          <TextAtm typography="nano1">{_t("prices.from")}</TextAtm>
        </BreakpointVisibilityOrg>
      </PushOrg>

      <ProductPrice
        hasTextColor
        plan={sortedDomains}
        priceTypography={{
          large: "big3",
          medium: "big3",
          small: "small3",
        }}
        currencyTypography={{
          large: "nano1",
          medium: "nano1",
          small: "xsmall2",
        }}
        suffixTypography={{
          large: "nano2",
          medium: "nano2",
          small: "nano1",
        }}
      />
    </FlexContainerOrg>
  )

  return (
    <>
      <ConditionalWrapper
        condition={!hidePrice}
        wrapper={(children) => (
          <FlexContainerOrg
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <PushOrg
              rightSpace={{ small: 1, medium: 1, large: 2 }}
              leftSpace={{ large: 0, medium: 0, small: 1 }}
              hasNoReset
            >
              {priceElement}
            </PushOrg>

            {children}
          </FlexContainerOrg>
        )}
      >
        <ButtonAtm
          color={buttonColor?.color}
          variant={buttonVariant?.variant}
          title={title}
          onClick={() => {
            sendGaEvent(gaEvent)
            handleOpenOverlay()
          }}
        >
          {text}
        </ButtonAtm>
      </ConditionalWrapper>

      {isOpen ? (
        <OverlayContainerOrg
          hasSections
          size={sizes.FULL}
          startPosition={startPositions.BOTTOM}
          header={<TextAtm typography="xhuge">{header}</TextAtm>}
          onCloseButtonClick={handleOpenOverlay}
          backgroundColor="backgroundDefaultToLight"
          children={
            <PushOrg topSpace={4} hasNoReset>
              <AlignContentOrg horizontalAlign="center" verticalAlign="center">
                <ContainerOrg>
                  <EmbeddedDomainSearch domains={sortedDomains} />
                </ContainerOrg>
              </AlignContentOrg>
            </PushOrg>
          }
        />
      ) : null}
    </>
  )
}

export default DomainButtonWithPrice
