import type { ReactElement } from "react"
import React from "react"
import { getFormattedPeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import ProductLinkMol from "@onestore/hel/dist/components/molecules/ProductLinkMol"
import type { CoreProduct } from "@gatsby-plugin-generic-page/fragments/coreProductsSection"
import { useLinkReplacer } from "@gatsby-plugin-generic-page/hooks/useLinkReplacer"
import {
  SquidexProductTileLinkTypes,
  SquidexProductTilePriceTypes,
} from "@gatsby-plugin-generic-page/types/productTileTypes"
import { sendGaEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

export interface ProductTileProps extends CoreProduct {
  hasSiblingWithLabel?: boolean
}

export default function ProductTile({
  title,
  linkType,
  href,
  pages,
  priceType,
  plan,
  customPrice,
  labelText,
  labelVariant = "notify",
  pricePrefix,
  priceSuffix,
  gaEvent,
  hasSiblingWithLabel,
}: ProductTileProps): ReactElement<ProductTileProps> {
  const to =
    linkType === SquidexProductTileLinkTypes.INTERNAL_LINK &&
    pages &&
    pages[0].flatData.url
      ? pages[0].flatData.url
      : undefined
  const linkHref = useLinkReplacer(href)

  let itemPrice: string | undefined

  if (
    priceType === SquidexProductTilePriceTypes.PRICE_PLAN_TYPE &&
    !isEmpty(plan)
  ) {
    if (!isEmpty(plan)) {
      itemPrice = getFormattedPeriodInfo(
        plan[0].flatData.periods,
        undefined,
        PriceFormat.REGISTER_GROSS_NO_PERIOD
      )
    }
  }

  if (
    priceType === SquidexProductTilePriceTypes.PRICE_CUSTOM_TYPE &&
    customPrice
  ) {
    itemPrice = customPrice
  }

  return (
    <ProductLinkMol
      title={title}
      labelText={labelText}
      labelVariant={labelVariant}
      price={{
        prefix: pricePrefix || "",
        value: itemPrice || "",
        suffix: priceSuffix || "",
      }}
      hrefTitle={title}
      hasSiblingWithLabel={hasSiblingWithLabel}
      to={to}
      href={
        linkType === SquidexProductTileLinkTypes.EXTERNAL_LINK && linkHref
          ? linkHref
          : undefined
      }
      onClick={() => sendGaEvent(gaEvent)}
    />
  )
}
