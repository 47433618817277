import type { ReactElement } from "react"
import { useState } from "react"
import React from "react"
import _t from "@core/i18n"
import TemplateMiniatureMol from "@onestore/hel/dist/components/molecules/TemplateMiniatureMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ExpandableContentOrg from "@onestore/hel/dist/components/organisms/ExpandableContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import type { Template } from "@gatsby-plugin-generic-page/components/DevicesPreviewModal"
import DevicesPreviewModal from "@gatsby-plugin-generic-page/components/DevicesPreviewModal"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { TemplateStaticImages } from "@gatsby-plugin-generic-page/fragments/webTemplate"
import type { WebTemplatesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/webTemplatesSection"
import type { GaEvent } from "~/fragments/gaEvent"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import { sendGaEvent } from "~/lib/ga4"
import { sendContentShowEvent, sendToplayerShowEvent } from "~/lib/ga4"

export default function WebTemplatesSection({
  header,
  webTemplates,
  footer,
  sectionSettings,
}: WebTemplatesSectionFlatData): ReactElement<WebTemplatesSectionFlatData> {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [activeTemplate, setActiveTemplate] = useState<Template>({
    title: "",
  })

  const handleClickModalOpen = (
    title: string,
    link?: string,
    staticImages?: TemplateStaticImages
  ) => {
    sendToplayerShowEvent(title)

    setIsModalOpen(true)
    setActiveTemplate({
      title,
      link,
      staticImages,
    })
  }

  const handleClickModalClose = () => {
    setIsModalOpen(false)
  }

  const handleClickPreviewButton = (
    title: string,
    link?: string,
    staticImages?: TemplateStaticImages,
    gaEvent?: GaEvent
  ) => {
    sendGaEvent(gaEvent)

    handleClickModalOpen(title, link, staticImages)
  }

  return (
    <>
      <BaseSection {...sectionSettings}>
        <BaseContainer {...sectionSettings?.containerSettings}>
          {header ? (
            <PushOrg bottomSpace={10}>
              <AlignContentOrg
                horizontalAlign={{
                  small: "left",
                  medium: "center",
                  large: "center",
                }}
              >
                <Header {...header} />
              </AlignContentOrg>
            </PushOrg>
          ) : null}

          <ExpandableContentOrg
            heightBreakpoint="templates"
            showMoreDetails={{
              text: _t("webTemplates.moreInfo"),
              title: _t("webTemplates.moreInfo"),
              onClick: sendContentShowEvent,
            }}
            showMoreType="link"
            hasCenteredShowMore
          >
            <GridOrg
              columns={{ small: 1, medium: 1, large: 2 }}
              gutterSpace={{ small: 5, medium: 6, large: 5 }}
            >
              {webTemplates.map((template) => {
                const { image, title, link, staticImages, gaEvent } =
                  template.flatData

                return (
                  <AlignContentOrg
                    key={template.id}
                    horizontalAlign={{
                      small: "center",
                      medium: "center",
                      large: "left",
                    }}
                  >
                    <TemplateMiniatureMol
                      image={assetsSquidexToImageObject(image, title)}
                      title={title}
                      button={{
                        text: _t("webTemplates.preview"),
                        title: _t("webTemplates.preview"),
                        onClick: () =>
                          handleClickPreviewButton(
                            title,
                            link,
                            staticImages,
                            gaEvent
                          ),
                      }}
                    ></TemplateMiniatureMol>
                  </AlignContentOrg>
                )
              })}
            </GridOrg>
          </ExpandableContentOrg>

          {footer ? (
            <PushOrg topSpace={10}>
              <AlignContentOrg
                horizontalAlign={{
                  small: "left",
                  medium: "center",
                  large: "center",
                }}
              >
                <MarkdownText>{footer}</MarkdownText>
              </AlignContentOrg>
            </PushOrg>
          ) : null}
        </BaseContainer>
      </BaseSection>

      <DevicesPreviewModal
        isOpen={isModalOpen}
        template={activeTemplate}
        handleClickModalClose={handleClickModalClose}
      />
    </>
  )
}
