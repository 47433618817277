import type { ReactElement } from "react"
import React from "react"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import ContentWithItemsOrg from "@onestore/hel/dist/components/organisms/ContentWithItemsOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import TilesLinkOrg from "@onestore/hel/dist/components/organisms/TilesLinkOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import TileLink from "@gatsby-plugin-generic-page/components/TileLink"
import type { ContentWithItemsSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithItemsSection"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import getResponsiveBackgroundImage from "@gatsby-plugin-generic-page/helpers/getResponsiveBackgroundImage"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import { sendGaEvent } from "~/lib/ga4"

export default function ContentWithItemsSection({
  header,
  ctaElements,
  showCtaOnMobileOnly,
  items,
  tilesId,
  gaEvent,
  responsiveImages,
  background,
  banerLink,
  sectionSettings,
}: ContentWithItemsSectionFlatData): ReactElement<ContentWithItemsSectionFlatData> {
  const responsiveBackgroundImage = getResponsiveBackgroundImage(
    responsiveImages,
    "top center",
    true
  )

  const mobileImage = responsiveImages.small
    ? assetsSquidexToImageObject(responsiveImages.small, "")
    : assetsSquidexToImageObject(responsiveImages.large, "")

  const content = (
    <>
      {header ? (
        <Header
          titleTypography={{
            small: "huge",
            medium: "lgiant",
            large: "lgiant",
            huge: "lgiant",
          }}
          textTypography={{ default: "big2", bold: "big4" }}
          {...header}
        />
      ) : null}

      {ctaElements ? (
        <ConditionalWrapper
          condition={!!showCtaOnMobileOnly}
          wrapper={(children) => (
            <BreakpointVisibilityOrg from="small" to="large">
              {children}
            </BreakpointVisibilityOrg>
          )}
        >
          <PushOrg topSpace={{ small: 3, medium: 4, large: 4 }} hasNoReset>
            <CtaElement ctaElement={ctaElements} />
          </PushOrg>
        </ConditionalWrapper>
      ) : null}
    </>
  )

  const { backgroundThemeColor, customColor } = getBackground(background)
  const banerLinkElement = banerLink ? getLinkProps(banerLink) : undefined

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ContentWithItemsOrg
          content={content}
          backgroundColor={
            backgroundThemeColor ? backgroundThemeColor : "background"
          }
          backgroundImage={responsiveBackgroundImage}
          backgroundLink={
            banerLinkElement
              ? {
                  to: banerLinkElement.to,
                  href: banerLinkElement.href,
                  title: banerLinkElement.title,
                  openInNewTab: banerLinkElement.openInNewTab,
                  onClick: banerLinkElement.onClick,
                }
              : undefined
          }
          mobileImage={mobileImage}
          customColor={customColor}
          onClick={() => sendGaEvent(gaEvent)}
        >
          <TilesLinkOrg columns={items.length} id={tilesId}>
            {items.map((item) => (
              <TileLink key={item.title} {...item} />
            ))}
          </TilesLinkOrg>
        </ContentWithItemsOrg>
      </BaseContainer>
    </BaseSection>
  )
}
