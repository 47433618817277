import { useCallback } from "react"
import _t from "@core/i18n"
import type { MainOrgProps } from "@onestore/hel/dist/components/organisms/PageHeaderOrg/components/MainOrg"
import useDomainHookUrl from "@gatsby-plugin-domain-search/hooks/useDomainHookUrl"
import type { GaEvent } from "~/fragments/gaEvent"
import { hasHostingHookEnabled } from "~/lib/config"
import { sendGaEvent } from "~/lib/ga4"
import { STORAGE } from "~/lib/storage"
import SessionStorage from "~/lib/storage/SessionStorage"
import url from "~/lib/url"
import { useAppSelector } from "~/store/hooks"
import { getBasketItemsCount, isBasketLoading } from "~/store/selectors"

export default function useCart(
  disableCartClick: boolean,
  gaEvent?: GaEvent
): MainOrgProps["cart"] {
  const count = useAppSelector(getBasketItemsCount)
  const loading = useAppSelector(isBasketLoading)
  const { forMiniCart } = useDomainHookUrl()

  const hookAddedToBasket: boolean =
    SessionStorage.getBool(STORAGE.DOMAIN_HOOK_ADDED_TO_BASKET) || false

  let to: undefined | string = url.getBasketUrl()
  let href: undefined | string = undefined
  let eventLabel: string | undefined = undefined

  if (hasHostingHookEnabled() && hookAddedToBasket) {
    if (forMiniCart) {
      href = forMiniCart // musi być jako href ponieważ ax nie działa gdy przejdziemy przez navigate
      to = undefined
      eventLabel = "domainSearch"
    }
  }

  const handleCartClick = useCallback(
    (event) => {
      sendGaEvent(gaEvent)

      if (disableCartClick) {
        event.preventDefault()
        event.stopPropagation()

        return
      }
    },
    [disableCartClick, eventLabel]
  )

  return {
    onClick: handleCartClick,
    counter: count,
    to,
    href,
    title: _t("basket.cart.title"),
    isLoading: loading,
  }
}
